@secondary-color: #ff00ae;
@background-color: #fffdf4;
@primary-transition: all 800ms;
@screen-md: 768px;

.floating-booking-summary {
  position: fixed;
  bottom: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
  z-index: 100;
  transition: @primary-transition;

  .floating-booking-summary-container {
    background-color: @background-color;
    width: 85%;
    position: relative;
    border-radius: 8px;
    padding: 10px 20px;
    transition: @primary-transition;
    box-shadow: 0px -2px 12px 0px #0000000f;

    .collapsible-action {
      position: absolute;
      transition: all 500ms;
      background-color: @secondary-color;
      width: 24px;
      height: 24px;
      border-radius: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      top: -12px;
      left: 0;
      right: 0;
      margin: auto;

      svg {
        path {
          fill: white;
        }
      }
    }

    .floating-booking-summary-action-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      transition: @primary-transition;

      .floating-booking-summary-preview {
        display: flex;
        flex: 1;
        justify-content: space-between;
        padding: 0 30px;
        align-items: center;
      }

      .action-btn {
        font-weight: bold;
        padding: 8px 24px;
        border: none;
        &.ant-btn-primary:not([disabled]) {
          background: @secondary-color;
          color: white;
          &:hover {
            background: #ea009f;
          }
        }
      }

      &.hide {
        display: none;
      }
    }

    .floating-booking-summary-content-container {
      overflow-y: hidden;

      .floating-booking-summary-content-wrapper {
        transition: @primary-transition;
        max-height: 80vh;

        .floating-booking-summary-content-title {
          font-family: "helsinki", sans-serif;
          font-size: 25px;
          margin-bottom: 20px;
        }

        .floating-booking-summary-content {
          padding: 30px 20px;
        }

        &.overflow-scroll {
          overflow-y: scroll;
        }
      }
    }
  }

  &.active {
    bottom: 0;
    .floating-booking-summary-container {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;

      .collapsible-action {
        transform: rotate(180deg);
      }
    }
  }

  @media only screen and (max-width: @screen-md) {
    .floating-booking-summary-container {
      width: 90%;

      .floating-booking-summary-content {
        padding: 10px 0 0 0 !important;
        overflow-x: hidden;
      }
    }
  }
}

.floating-booking-summary-backdrop {
  position: fixed;
  opacity: 0.4;
  left: 0;
  right: 0;
  z-index: 100;
  transition: @primary-transition;
  &.active {
    transition: @primary-transition;
    top: 0;
    bottom: 0;
    background-color: #232d3b;
  }
}

.floating-booking-summary-item {
  .img-value {
    width: 26px;
    height: 26px;
    margin-right: 6px;
  }
}
