#auth-layout{
  min-height: 100vh;
  padding: 40px 20px;
  .auth-container{
    margin: auto;
    width: 100%;
    max-width: 440px;
    .logo-wrapper{
      text-align: center;
      width: 196px;
      margin: 0 auto 28px;
    }
  }

  @media only screen and (max-width: 768px){
    padding: 20px;
  }
}
