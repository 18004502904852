@secondary-color: #ff00ae;

.event-card {
  cursor: pointer;
  display: flex;
  flex-direction: column;

  &:hover {
    .image-wrapper {
      .image {
        transform: scale(1.1);
      }
    }
  }

  .image-wrapper {
    max-height: 200px;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    overflow: hidden;

    .image {
      height: 200px;
      object-fit: cover;
      object-position: center center;
      transform: scale(1);
      transition: all 0.5s ease;
    }
  }

  .info-wrapper {
    background: #F6F7F7;
    padding: 30px 25px;
    margin-top: -30px;
    position: relative;
    border-radius: 15px;
    flex-grow: 1;

    .name {
      line-height: 1.5;
      font-weight: 700;
      min-height: 52px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    .date {
      margin: 0px;
      display: flex;
      align-items: center;
      font-size: 12px;

      .icon {
        margin-right: 5px;

        path {
          fill: @secondary-color;
        }
      }
    }
  }
}