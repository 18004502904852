@secondary-color: #ff00ae;
@primary-color: #FEDF47;

.vui-spin{
  &.ant-spin-lg .ant-spin-dot{
    font-size: 36px;
  }

  .secondary-type{
    .ant-spin-dot-item{
      background-color: @secondary-color;
    }
  }

  .primary-type{
    .ant-spin-dot-item{
      background-color: @primary-color;
    }
  }
}
