#auth-social-callback-page{
  .auth-social-callback-wrapper{
    text-align: center;
    .vui-content-loading{
      min-height: 80px;
      .vui-spin.ant-spin-lg .ant-spin-dot{
        font-size: 32px;
      }
    }
  }
}
