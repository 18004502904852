@screen-md: 768px;
@background-color: #FFF9DA;
@secondary-color: #ff00ae;
@default-border-radius: 10px;

#app-faq-page{
  padding: 40px 0;
  line-height: 1.5;
  .faq-wrapper{
    margin-top: 8em;

    [data-theme='compact'] .faq-collapse .faq-panel,
    .faq-collapse .faq-panel {
      margin-bottom: 24px;
      overflow: hidden;
      background: @background-color;
      border: 0 !important;
      border-radius: @default-border-radius;

      &.ant-collapse-item-active {
        .ant-collapse-header {
          color: @secondary-color;
        }
      }

      .ant-collapse-header {
        font-weight: 700;
        font-size: 18px;
        margin: .75em .5em;

        .ant-collapse-arrow {
          font-size: 18px;
          color: black;
        }

        @media only screen and (max-width: @screen-md) {
          font-size: 16px;
        }
      }

      .ant-collapse-content-box {
        margin: .5em;
      }
    }
  }

  @media only screen and (max-width: @screen-md){
    padding: 40px 15px;

    .faq-wrapper {
      margin-top: 5em;
    }
  }
}
