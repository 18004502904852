@secondary-color: #ff00ae;
@primary-color: #f9df64;

#account-schedule {
  .account-schedule-helpers {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 30px;
    .account-schedule-helper {
      display: flex;
      align-items: center;
      gap: 12px;
      font-size: 16px;
      .bullet {
        width: 14px;
        height: 14px;
        background-color: @secondary-color;
        border-radius: 50%;

        &.yellow {
          background-color: @primary-color;
        }

        &.green {
          background-color: #67eae5;
        }
      }
      .line {
        width: 30px;
        height: 2.5px;
        background-color: @secondary-color;
      }
    }
  }

  .vui-calendar {
    &.ant-picker-calendar-full .ant-picker-panel {
      .ant-picker-date-panel {
        .ant-picker-cell
          .ant-picker-calendar-date
          .ant-picker-calendar-date-content {
          height: 30px;
        }
      }
      .ant-picker-month-panel {
        .ant-picker-cell
          .ant-picker-calendar-date
          .ant-picker-calendar-date-content {
          height: 60px;
        }
      }
    }
  }
}
