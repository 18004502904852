@secondary-color: #ff00ae;
@default-border-color: #edeeef;
@text-color: #232d3b;

#cart-payment-step{
  .ant-form{
    margin-top: 20px;
  }
  .card-payment-title{
    color: @text-color;
    font-weight: 700;
  }
  .cart-payment-step-text{
    color: @secondary-color;
    font-weight: 700;
  }
  .bank-payment-body{
    width: 100%;
    .ant-space-item{
      width: 100%;
      padding-bottom: 0;
      &:not(:last-child){
        padding: 8px 0 16px;
        border-bottom: 2px solid @default-border-color;
      }
      .bank-payment-credit-card{
        .bank-payment-credit-title{
          display: block;
          font-weight: bold;
          margin-bottom: 24px;
          font-size: 18px;
        }
        margin-top: 16px;
      }
    }
    .bank-payment-wrapper{
      display: flex;
      flex-direction: column;
      padding-left: 12px;
      .bank-payment-name{
        font-weight: bold;
        margin: 10px 0 4px;
      }
      .bank-payment-image{
        max-width: 70px;
      }
    }
  }
}
