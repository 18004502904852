#account-reschedule-success-page{
  .reschedule-success-wrapper{
    text-align: center;
    margin-top: 60px;
    .reschedule-success-title{
      font-family: "helsinki", sans-serif;
      font-size: 24px;
      text-align: center;
    }
    .ant-spin-nested-loading{
      width: fit-content;
      margin: auto;
    }
  }
}
