@secondary-color: #ff00ae;

.insight-card {
  cursor: pointer;

  &:hover {
    .image-wrapper {
      .image {
        transform: scale(1.1);
      }
    }
  }

  .image-wrapper {
    max-height: 174px;
    border-radius: 15px;
    overflow: hidden;

    .image {
      height: 174px;
      object-fit: cover;
      object-position: center center;
      transform: scale(1);
      transition: all 0.5s ease;
    }
  }

  .info-wrapper {
    padding: 15px 0px;
    position: relative;
    border-radius: 15px;

    .label {
      font-size: 12px;
      font-weight: 700;
      color: #fc3f93;
    }

    .name {
      line-height: 1.5;
      font-weight: 700;
    }

    .summary {
      margin-bottom: 5px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }

    .vertical-list {
      list-style: none;
      padding: 0px;
      margin: 0px;
      display: flex;
      flex-wrap: wrap-reverse;
      align-items: center;
      gap: 15px;
      font-size: 12px;
      font-weight: 700;
      .item {
        display: flex;
        gap: 5px;

        .icon {
          transform: translateY(5px);
          path {
            fill: #fc3f93;
          }
        }

        .item-text {
          flex: 1;
        }
      }
    }
  }
}
