@screen-md: 768px;

.booking-session-item {
  .ant-collapse-item {
    .ant-collapse-header {
      align-items: center;
      padding-left: 0;

      .ant-collapse-extra {
        margin-right: 24px;
      }
    }
    .ant-collapse-content-box {
      padding: 0 !important;
    }
  }

  @media only screen and (max-width: @screen-md) {
    .session-interval-time {
      display: none;
    }
  }
}
