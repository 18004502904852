.schedule-detail-modal-body {
  .title-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;

    .ant-typography {
      margin-bottom: 0;
    }
  }
}
