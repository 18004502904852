@primary-color: #fedf47;
@border-color: #ededed;
@border-color-active: #67eae5;

#auth-otp-verification-page {
  .auth-otp-verification-wrapper {
    text-align: center;
    .auth-otp-verification-title {
      font-family: "helsinki", sans-serif;
      font-size: 24px;
      text-align: center;
    }
  }

  .ant-divider {
    margin: 12px 0;
  }

  .otp-input-container {
    justify-content: center;
    gap: 12px;
  }

  .otp-input {
    width: 50px !important;
    height: 50px;
    font-size: 20px;
    font-weight: bold;
    border-radius: 8px;
    border: 2px solid @border-color;

    &:focus {
      outline-color: @border-color-active;
    }

    &:not([value=""]) {
      background-color: #eafffe;
      border: none;
    }

    &.error {
      background-color: #fff2f0 !important;
      border: 2px solid #ff290b !important;

      &:focus {
        outline-color: #ff290b !important;
      }
    }
  }

  .alert-succes-text {
    font-weight: bold;
    color: #48d581;
  }
}
