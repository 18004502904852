@font-face {
  font-family: "helsinki";
  src: url("../assets/fonts/helsinki/helsinki.ttf") format("truetype");
}

@font-face {
  font-family: "Century Gothic";
  src: url("../assets/fonts/century-gothic/CenturyGothic.ttf")
    format("truetype");
}

@font-face {
  font-family: "Century Gothic Bold";
  src: url("../assets/fonts/century-gothic/CenturyGothicBold.ttf")
    format("truetype");
}

@font-face {
  font-family: "Century Gothic";
  src: url("../assets/fonts/century-gothic/CenturyGothicBold.ttf")
    format("truetype");
  font-weight: bold;
}

body {
  font-family: "Century Gothic", sans-serif;
  overflow-x: hidden;
  line-height: 2;

  @media only screen and (max-width: @screen-md) {
    font-size: 14px;
  }
}

.container {
  width: 100%;
  margin: auto;
}

.make-container(@minWidth, @breakpoint) {
  @media (min-width: @minWidth) {
    .container {
      max-width: @breakpoint;
    }
  }
}

.make-container(@screen-xs-min, @screen-xs);
.make-container(@screen-sm-min, @screen-sm);
.make-container(@screen-md-min, @screen-md);
.make-container(@screen-lg-min, @screen-lg);
.make-container(@screen-xl-min, @screen-xl);

.loading-screen {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

#app-layout {
  font-family: @font-family;

  .app-page {
    min-height: 100vh;
    @media only screen and (max-width: @screen-md) {
      &.dark {
        background-color: rgba(0, 0, 0, 0.3);
      }
    }
  }

  .app-float-wrapper {
    position: fixed;
    z-index: 10;
    right: 20px;
    bottom: 20px;

    .app-float-head {
      text-align: center;
      transition: all 200ms ease-in-out;
      opacity: 1;

      &.hidden {
        visibility: hidden;
        opacity: 0;
      }

      span {
        font-family: "helsinki", sans-serif;
        line-height: 1.2em;
        display: block;
        background-color: #fff;
        border-radius: 0.25em;
        font-size: 18px;
      }

      img {
        margin-top: 0.3em;
        width: 25px;
      }
    }

    .app-float-item {
      background: #ffffff;
      outline: none !important;
      border: 0 !important;
      box-shadow: 0px 4px 16px rgba(2, 10, 49, 0.1);
      border-radius: 100px;
      width: 65px;
      height: 65px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      padding: 0;

      &.bg-blue {
        background-color: #77dad5;
      }

      &.bg-tosca {
        background-color: #8aede8;
      }

      &.bg-green {
        background-color: #40c351;
      }

      &:not(:first-child) {
        margin-top: 10px;
      }

      img {
        width: 38px;
        object-fit: contain;
        transition: all 500ms ease;

        &.float-image-customer-service {
          width: 100%;
        }
      }

      &.with-hover-text {
        .app-float-item-hover {
          display: none;
          font-family: "Century Gothic Bold", sans-serif;
          font-size: 14px;
          font-weight: bold;
          color: #232d3b;
          line-height: 16px;
        }

        img {
          display: block;
        }

        &:hover {
          background: #fedf47;

          .app-float-item-hover {
            display: block;
          }

          img {
            display: none;
          }
        }
      }
    }

    @media only screen and (max-width: @screen-md) {
      .app-float-item {
        width: 56px;
        height: 56px;

        img {
          width: 31px;
        }

        &.with-hover-text {
          .app-float-item-hover {
            font-size: 8px;
          }
        }
      }
    }
  }
}
