@screen-md: 768px;
@secondary-color: #ff00ae;
@lighter-tosca: #F4FCFC;

#app-psychologists-detail-page{
  overflow: hidden;
  padding: 40px 0;
  .psychologists-info-section{
    margin: 20px 0 40px;
    display: flex;
    justify-content: space-between;
    gap: 60px;
    .psychologists-info-left{
      .psychologists-image{
        max-width: 444px;
        min-width: 444px;
      }
    }
    .psychologists-info-right{
      .psychologists-name{
        font-size: 28px;
        font-weight: bold;
        margin-bottom: 0;
      }
      .psychologists-title{
        margin-top: -6px;
        color: @secondary-color;
        margin-bottom: 28px;
        font-weight: bold;
      }
      .psychologists-description{
        margin-bottom: 40px;
      }
    }
  }

  .related-psychologists{
    .vui-title-wrapper{
      margin-bottom: 80px;
    }
    .related-psychologists-wrapper{
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 40px;
    }
  }

  @media only screen and (max-width: @screen-md){
    .psychologists-info-section{
      margin: 40px 0 20px;
      padding: 0 15px;
      flex-direction: column;
      gap: 40px;
      .psychologists-info-left{
        .psychologists-image{
          min-width: unset;
          width: 100%;
          margin: auto;
        }
      }
    }

    .related-psychologists{
      .vui-title-wrapper{
        margin-bottom: 60px;
      }
      .related-psychologists-wrapper{
        grid-template-columns: repeat(1, 1fr);
      }
    }
  }
}
