@screen-md: 768px;

.default-modal{
  .default-modal-header{
    margin-bottom: 24px;
    .secondary-title-wrapper{
      svg{
        cursor: pointer;
      }
    }
  }
  .ant-modal-content{
    border-radius: @default-border-radius;
    .ant-modal-header{
      border-bottom: none;
    }

    .default-modal-body{
      border-radius: @default-border-radius;
      .default-modal-avatar{
        display: flex;
        align-items: center;
        gap: 20px;
        justify-content: space-between;
        margin-bottom: 24px;
        .default-modal-avatar-button-wrapper{
          display: flex;
          align-items: center;
          gap: 20px;
        }
      }
      .ant-modal-header{
        border-bottom: none;
      }
    }
  }

  @media only screen and (max-width: @screen-md){
    .ant-modal-content{
      .default-modal-body{
        .default-modal-avatar{
          flex-direction: column;
          .ant-avatar{
            width: 120px !important;
            height: 120px !important;
          }
        }
      }
    }
  }
}
