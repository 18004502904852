#auth-verification-page{
  .auth-verification-wrapper{
    text-align: center;
    .auth-verification-title{
      font-family: "helsinki", sans-serif;
      font-size: 24px;
      text-align: center;
    }
  }
}
