@screen-md: 768px;

#linkedin-wall{
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 40px;
  padding: 25px 0;
  .linkedin-wall-card{
    width: 100%;
    height: 100%;
    max-width: 380px;
    margin: auto;

    .ant-image{
      width: 100%;

      .ant-image-img{
        max-height: 270px;
        object-fit: cover;
        border-radius: 8px;
      }
    }
  }

  @media only screen and (max-width: @screen-md){
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 20px;
    padding: 0 0 40px 0;
  }
}
