@secondary-color: #ff00ae;

#event-detail {
  min-height: 100vh;

  .image-wrapper {
    margin-top: 50px;
    margin-bottom: 50px;
    border-radius: 15px;
    overflow: hidden;
  }

  .title {
    font-family: "helsinki", sans-serif;
    font-size: 26px;
    font-weight: 700;
    margin-bottom: 0px;
  }

  .date {
    margin-bottom: 30px;
    display: flex;
    align-items: center;

    .icon {
      margin-right: 5px;

      path {
        fill: @secondary-color;
      }
    }
  }

  .description {
    margin-bottom: 50px;
  }

  .share-wrapper {

    .share-title {
      font-family: "helsinki", sans-serif;
      font-weight: 700;
    }

    .socmed-list {
      list-style: none;
      margin: 0px;
      padding: 0px;
      display: flex;
      align-items: center;
      gap: 15px;

      .item {
        display: flex;
        align-items: center;
        justify-content: center;
        background: @secondary-color;
        width: 35px;
        height: 35px;
        border-radius: 100%;
        cursor: pointer;

        .icon {
          path {
            fill: white;
          }
        }
      }
    }
  }
}