@secondary-color: #ff00ae;

#account-history-body {
  .account-history-title-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 25px;

    .vui-secondary-title-wrapper {
      margin-bottom: 0;
    }
  }

  .history-filter {
    color: @secondary-color;
  }
}

.account-history-status-select-dropdown-menu {
  border-radius: 8px;
  padding: 6px;

  .ant-dropdown-menu-item {
    background-color: white;
  }
  .ant-checkbox-wrapper {
    .ant-checkbox {
      &:hover,
      &:focus {
        .ant-checkbox-inner {
          border-color: #ff00ae;
        }
      }
      &.ant-checkbox-checked .ant-checkbox-inner {
        background-color: #ff00ae;
        border-color: #ff00ae;
      }
    }
    &:hover {
      .ant-checkbox-inner {
        border-color: #ff00ae;
      }
    }
  }
}
