@screen-md: 768px;
@default-border-radius: 8px;

#app-privacy-policy-page{
  padding: 40px 0;
  line-height: 1.5;
  .privacy-policy-wrapper{
    img{
      border-radius: @default-border-radius;
      margin-bottom: 40px;
    }
  }
  @media only screen and (max-width: @screen-md){
    padding: 40px 15px;
  }
}
