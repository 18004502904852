#app-home-page {
  img {
    width: 100%;
  }
  .cursor-pointer {
    cursor: pointer;
  }
  .home-banner {
    .swiper-slide {
      .home-banner-item {
        min-height: 680px;
        background-size: cover;
        display: flex;
        align-items: center;
        position: relative;

        .ant-skeleton-avatar-square {
          width: 100%;
          height: 100%;
        }

        .home-banner-item-image {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
          z-index: -1;
        }

        .home-banner-item-content {
          width: 45%;
          display: block;
          margin: auto 0 auto auto;
          padding-right: 2rem;

          h3 {
            font-weight: 700;
            font-size: 54px;
            line-height: 1.2;
            text-transform: uppercase;
            letter-spacing: 1px;
            font-family: "helsinki", sans-serif;
            color: #232d3b;
            margin-bottom: 15px;
            z-index: 2;

            * {
              margin-bottom: 0 !important;
            }

            span {
              position: relative;
              z-index: 1;

              &:after {
                content: "";
                position: absolute;
                top: 8px;
                left: 0;
                width: 100%;
                height: 65%;
                background: #67eae5;
                z-index: -1;
                border-radius: 30px;
              }
            }
          }

          .ant-typography {
            font-family: "Century Gothic", sans-serif;
            font-size: 16px;
            font-weight: 700;
            z-index: 2;
            margin-bottom: 0;
            letter-spacing: 0.01em;
          }
        }
      }
    }

    .swiper-pagination {
      bottom: 50px;
    }
  }
  .home-about-us {
    height: 100%;
  }
  .secondary-title {
    line-height: 1.5;
  }
  .section-description {
    margin-bottom: 24px;

    * {
      line-height: 2;
    }
  }
  .our-services {
    .swiper {
      height: 100%;
    }
    .swiper-pagination {
      position: relative;
      bottom: unset;
      margin-top: 20px;
    }
  }
  .our-clients {
    .vui-title-wrapper {
      text-align: center;
      margin: auto auto 40px auto;
    }
    .card-our-client-wrapper {
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      grid-gap: 25px;
      padding: 40px 150px 60px;
    }
    .swiper {
      .swiper-pagination {
        bottom: -5px;
      }
    }
  }

  .box-shadow-home-client {
    transition: 0.8s;
  }

  .box-shadow-home-client:hover {
    -webkit-box-shadow: 0px 0px 17px 0px #d8d5d5 !important;
    -moz-box-shadow: 0px 0px 17px 0px #d8d5d5 !important;
    box-shadow: 0px 0px 17px 0px #d8d5d5 !important;
    transition: 0.7s;
  }

  // our services style
  .our-services {
    .our-services-title-desktop {
      display: block;
    }

    .our-services-title-mobile {
      display: none;
    }
  }

  // our office style
  .our-office {
    .our-office-slider-column {
      overflow: hidden;

      .swiper {
        padding: 40px 0 80px;
        margin-left: -35vw;

        &.slider-change {
          margin-left: 0 !important;

          .swiper-wrapper {
            margin-left: 0 !important;
            padding-left: 0 !important;
          }

          .swiper-pagination {
            left: 20vw;
          }
        }

        .swiper-wrapper {
          margin-left: -35vw;
          padding-left: 35vw;

          .swiper-slide {
            width: 280px;
            height: 440px;
            transition: width 0.5s ease;
            display: -webkit-box;
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            -webkit-justify-content: center;
            justify-content: center;
            -webkit-box-align: center;
            -ms-flex-align: center;
            -webkit-align-items: center;
            align-items: center;

            .card-slider-office {
              position: relative;
              overflow: hidden;

              &:before {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: #232d3b;
                opacity: 0.4;
                border-radius: 10px;
                z-index: 1;
                content: " ";
              }

              img {
                width: 100%;
              }
            }

            &.swiper-slide-active {
              width: 440px;

              .card-slider-office {
                &:before {
                  display: none;
                }
              }
            }
          }
        }

        .swiper-button-prev {
          left: 15px;

          &.swiper-button-disabled {
            display: none;
          }
        }

        .swiper-pagination {
          left: 55vw;
          bottom: -30px;
          transform: unset;
        }
      }
    }
  }

  // our merchandise style
  .our-merchandise {
    .vui-title-wrapper {
      text-align: center;
      margin-bottom: 40px;
    }
    .card-our-merchandise-wrapper {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-gap: 40px;
      padding: 40px 150px 60px;
    }
    .swiper {
      .swiper-pagination {
        bottom: -5px;
      }
    }
  }

  @media only screen and (max-width: 991px) {
    .our-office {
      .our-office-slider-column {
        .swiper {
          .swiper-pagination {
            left: 50%;
            transform: translateX(-50%);
          }
        }
      }
    }
  }

  @media only screen and (min-width: 769px) and (max-width: 1119px) {
    .our-clients {
      .card-our-client-wrapper {
        grid-template-columns: repeat(4, 1fr);
        padding: 40px 120px 60px;
      }
    }

    .our-merchandise {
      .card-our-merchandise-wrapper {
        padding: 40px 120px 60px;
      }
    }
  }

  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .home-banner {
      .swiper-slide {
        .home-banner-item {
          min-height: 480px;

          .home-banner-item-content {
            width: 50%;
            padding-right: 2.5rem;

            h3 {
              font-size: 38px;
            }
          }
        }
      }

      .swiper-pagination {
        bottom: 15px;
      }
    }

    .our-office {
      .swiper {
        .swiper-button-prev {
          left: 40px;
        }

        .swiper-pagination {
          bottom: 0 !important;
        }
      }
    }
  }

  @media only screen and (max-width: 768px) {
    .our-office {
      .swiper {
        margin: 0 !important;
        padding: 10px 0 0 0 !important;

        .swiper-wrapper {
          padding: 0 !important;
          margin: 0 !important;
        }

        .swiper-slide {
          width: 100% !important;
          height: unset !important;

          .card-slider-office {
            padding: 0 40px;

            &:before {
              display: none;
            }
          }
        }
      }
    }
  }

  @media only screen and (max-width: 767px) {
    .home-banner {
      position: relative;

      .swiper-slide {
        .home-banner-item {
          min-height: unset;

          .home-banner-item-image {
            position: relative;
            min-height: 470px;
          }

          .home-banner-item-content {
            width: 100%;
            margin-top: 15px;
            padding: 0 15px 15px 15px;
            text-align: center;

            h3 {
              font-size: 20px;
              margin-bottom: 10px;
            }

            .ant-typography {
              font-size: 14px;
            }
          }
        }
      }

      .swiper-button-next,
      .swiper-button-prev {
        width: 30px;
        height: 30px;
        top: 30%;

        &:after {
          font-size: 10px;
        }
      }

      .swiper-button-next {
        right: 15px;
      }

      .swiper-button-prev {
        left: 15px;
      }

      .swiper-pagination {
        position: relative;
        bottom: unset;
      }
    }

    .our-clients,
    .our-merchandise,
    .our-office {
      .swiper-button-next,
      .swiper-button-prev {
        width: 30px;
        height: 30px;

        &:after {
          font-size: 12px;
        }
      }

      .swiper-button-prev {
        left: 0;
      }

      .swiper-button-next {
        right: 0;
      }

      .swiper-pagination {
        position: relative;
        bottom: unset;
        margin-top: 20px;
      }
    }

    .our-office {
      .our-office-slider-column {
        .swiper {
          .swiper-pagination {
            bottom: 0;
            left: 50% !important;
          }
        }
      }
    }

    .our-clients {
      .vui-title-wrapper {
        text-align: left;
      }
      .swiper-wrapper {
        padding: 0;
      }
      .card-our-client-wrapper {
        padding: 0;
      }
    }

    .our-services {
      .our-services-slider {
        order: 1;
        margin-bottom: 15px;

        .swiper {
          padding-bottom: 10px;
        }

        .swiper-pagination {
          bottom: 0;
          margin-top: 10px;
        }
      }

      .our-services-information {
        order: 12;
      }

      .our-services-title-desktop {
        display: none;
      }

      .our-services-title-mobile {
        display: block;
        margin-bottom: 40px;
      }
    }
  }
}
