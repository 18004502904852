.no-history-wrapper {
  text-align: center;
  margin: auto;
  padding: 80px 15px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .no-history-text {
    margin: 20px 0;
    color: #69707a;
  }
}
