@text-color: #232d3b;
@screen-md: 768px;
@lighter-tosca: #F4FCFC;

#account-page{
  padding: 60px 0;
  line-height: 1.5;
  .account-tabs-wrapper{
    padding: 0 40px;

    .account-tabs-mobile{
      display: none;
    }

    .ant-tabs{
      .ant-tabs-nav{
        min-width: 240px;
      }
      &.ant-tabs-left > .ant-tabs-content-holder {
        border: 0;
      }
      .ant-tabs-nav-wrap {
        .ant-tabs-nav-list {
          .ant-tabs-ink-bar {
            display: none;
          }
        }
      }
      .ant-tabs-tab{
        padding: 10px 16px;
        border-radius: 8px;
        font-size: 14px;
        transition: all 0.5s ease;
        &.ant-tabs-tab-active, &:hover{
          background-color: @lighter-tosca;
          .ant-tabs-tab-btn{
            color: @text-color;
            font-weight: bold;
          }
        }
      }
      .tab-with-icon{
        display: flex;
        align-items: center;
        gap: 12px;
      }
    }
  }

  @media only screen and (max-width: @screen-md) {
    .account-tabs-wrapper {
      padding: 0 15px;
      .ant-tabs {
        .ant-tabs-nav {
          min-width: 50px;
        }
      }
    }
  }

  @media only screen and (max-width: 767px) {
    .account-tabs-wrapper{
      .account-tabs-mobile{
        display: block;
        margin-bottom: 20px;

        .ant-select{
          width: 100%;
  
          .ant-select-selector{
            background: #F4FCFC;
            border: 0;
  
            .ant-select-selection-item{
              .vui-custom-with-image{
                span{
                  color: #FC3F93;
                }
              }
            }
          }
        }
      }

      .ant-tabs-nav{
        display: none;
      }
    }
  }
}
