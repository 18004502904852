@screen-md: 768px;

#app-service-page{
  padding: 40px 0;
  line-height: 1.5;
  .service-wrapper{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 40px;
    margin-top: 50px;
  }
  @media only screen and (max-width: @screen-md){
    padding: 40px 15px;
    .service-wrapper{
      grid-template-columns: repeat(1, 1fr);
      grid-gap: 30px;
      margin-top: 40px;
    }
  }
}
