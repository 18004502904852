.vui-form-text-wrapper{
  .vui-form-text-label{
    margin-bottom: 4px;
    font-size: 14px;
  }
  .vui-form-text-value{
    font-size: 16px;
    margin-bottom: 0;
  }
  margin-bottom: 24px;
}
