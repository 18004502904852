#auth-forgot-password-success-page{
  .auth-forgot-password-success-wrapper{
    text-align: center;
    .auth-forgot-password-success-title{
      font-family: "helsinki", sans-serif;
      font-size: 24px;
      text-align: center;
    }
  }
}
