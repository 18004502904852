@secondary-color: #ff00ae;
@success-color: #48D581;
@screen-md: 768px;

.vui-steps{
  &.ant-steps-label-vertical{
    .ant-steps-item-tail{
      margin-left: 72px;
      top: 24px;
    }
  }
  .ant-steps-item{
    .ant-steps-item-container {
      position: relative;
      overflow-x: hidden;
      .ant-steps-item-content{
        width: 92px;
        .ant-steps-item-title{
          font-size: 14px;
        }
      }
      .ant-steps-item-icon{
        width: 36px;
        background-color: transparent;
        height: 36px;
        border-radius: 50%;
        justify-content: center;
        display: flex;
        margin: 10px 0 10px 28px;
        align-items: center;
        position: relative;
        .ant-steps-icon{
          width: 20px;
          height: auto;
          line-height: unset;
          font-size: unset;
          display: flex;
          svg{
            width: 20px;
            path{
              fill: #c6c8cc;
            }
          }
        }

        &:before{
          position: absolute;
          content: "";
          border-radius: 50%;
          width: 46px;
          height: 46px;
          background-color: transparent;
          border: 1px solid #c6c8cc;
        }
      }
    }

    &.ant-steps-item-finish {
      .ant-steps-item-container {
        .ant-steps-item-content {
          .ant-steps-item-title {
            font-weight: bold;
          }
        }
        .ant-steps-item-icon {
          width: 50px;
          margin: 10px 0 10px 22px;
          .ant-steps-icon {
            width: 100%;
            svg {
              width: 100%;
              path:first-child {
                fill: @success-color;
              }
              path:last-child {
                fill: white;
              }
            }
          }

          &:before {
            border: none;
          }
        }
        .ant-steps-item-tail {
          &:after {
            background-color: @success-color;
          }
        }
      }
    }
    
    &.ant-steps-item-active{
      .ant-steps-item-container{
        .ant-steps-item-content{
          .ant-steps-item-title{
            font-weight: bold;
          }
        }
        .ant-steps-item-icon{
          background-color: @secondary-color;
          .ant-steps-icon{
            svg path{
              fill: white;
            }
          }
          &:before{
            border: 1px solid @secondary-color;
          }
        }
      }
    }
  }

  @media only screen and (max-width: @screen-md){
    &.ant-steps-vertical{
      flex-direction: row;
      justify-content: center;
      
      .ant-steps-item{
        flex: 1 1 calc(85%/5);
        max-width: calc(85%/5);

        &:not(:last-child){
          margin-right: 10px;
        }
        
        .ant-steps-item-container{
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          overflow: unset;

          .ant-steps-item-tail{
            width: 30%;
            height: 1px;
            min-height: unset;
            padding: 0;
            top: 25%;
            content: " ";
            width: 30%;
            left: unset;
            right: -24%;
            background: #A7ABB1;

            &::after{
              display: none;
            }
          }

          .ant-steps-item-icon{
            width: 24px;
            height: 24px;
            margin: 5px;

            &:before{
              width: 34px;
              height: 34px;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
            }

            .ant-steps-icon{
              width: 14px;
              margin-left: -.5px;

              svg{
                width: 14px !important;
              }
            }
          }

          .ant-steps-item-content{
            min-height: unset;
            width: 100%;
            display: block;

            .ant-steps-item-title{
              font-size: 10px;
              padding-right: 0;
              color: #A7ABB1;
              text-align: center;
              display: block;
            }
          }
        }

        &.ant-steps-item-finish {
          .ant-steps-item-container {
            .ant-steps-item-icon {
              width: 34px;
              .ant-steps-icon {
                width: 100%;
                svg {
                  width: 100% !important;
                }
              }
            }
          }
        }

        &.ant-steps-item-active{
          .ant-steps-item-container{
            .ant-steps-item-content{
              .ant-steps-item-title{
                color: #000000;
              }
            }
          }
        }
      }
    }
  }
}
