// section style
.section {
  padding: 80px 0;
  overflow: hidden;
  @media only screen and (max-width: @screen-md) {
    padding: 40px 15px;
  }
}

// utilities
.secondary-title {
  font-weight: bold;
  font-size: 24px;
  position: relative;
  &:before {
    position: absolute;
    bottom: -10px;
    content: "";
    width: 30px;
    height: 3px;
    background-color: @secondary-color;
  }
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.text-center {
  text-align: center;
}

.text-align-center-md {
  @media only screen and (min-width: @screen-md) {
    text-align: center;
  }
}

.text-success-color {
  color: @success-color;
}

.text-secondary-color {
  color: @secondary-color;
}

.text-primary-color {
  color: @primary-color;
}

.text-bold {
  font-weight: bold;
}

.text-justify {
  text-align: justify;
}

.fs-12 {
  font-size: 12px;
}

.has-stab {
  position: relative;
  display: inline;

  &:before {
    content: "";
    position: absolute;
    left: 0;
    bottom: -4px;
    width: 100%;
    height: 16px;
    background: @secondary-color;
    opacity: 0.8;
    z-index: -1;

    @media only screen and (max-width: @screen-md) {
      height: 12px;
      left: 0;
      transform: translateX(0%);
    }
  }
}

.cursor-pointer {
  cursor: pointer;
}

.mb-16 {
  margin-bottom: 16px;
}

.mb-20 {
  margin-bottom: 20px;
}

.font-bold {
  font-weight: 700 !important;
}

.flex {
  display: flex;
}

//component
.package-label {
  background-color: @primary-color;
  padding: 3px 12px;
  border-radius: 8px;
  font-size: 12px;
  font-weight: 500;
}

.booking-time-select {
  display: flex;
  gap: 12px;
  flex-wrap: wrap;

  .ant-radio-button-wrapper {
    border-radius: 6px !important;
    border-left-width: 1px !important;
    color: #232d3b !important;
    height: unset;
    padding: 8.5px 20px;
    &::before {
      all: unset;
    }

    &.ant-radio-button-wrapper-checked {
      background-color: @primary-color;
    }
  }
}
