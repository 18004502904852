#counseling-form-success-page {
  padding-top: 48px;
  .counseling-form-success-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 30px;
    .logo-wrapper {
      width: 200px;
    }
    .counseling-form-success-card {
      text-align: center;
      padding: 0 80px;
      .counseling-form-success-title {
        font-family: "helsinki", sans-serif;
        font-size: 24px;
        text-align: center;
        margin-bottom: 10px;
      }
    }
  }
}
