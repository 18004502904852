@screen-md: 768px;

.form-radio-scale-wrapper{
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  .radio-scale-wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }
  .ant-radio-wrapper{
    flex-direction: column-reverse;
    align-items: center;
    &:after{
      display: none;
    }
  }

  @media only screen and (max-width: @screen-md){
    flex-direction: column;
    .radio-scale-wrapper{
      flex-direction: column;
    }
  }
}
