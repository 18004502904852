@secondary-color: #ff00ae;
@primary-color: #f9df64;

.booking-tag {
  display: flex;
  align-items: center;
  .booking-tag-title {
    font-size: 12px;
    color: #a7abb1;
    margin: 0 6px 0 4px;
    font-weight: bold;
  }

  .counseling-tag {
    font-size: 10px;
    color: white;
    padding: 1px 8px;
    background-color: @secondary-color;
    border-radius: 20px;
    font-weight: bold;

    &.package {
      color: #232d3b;
      background-color: @primary-color;
    }
  }
}
