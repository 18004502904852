@primary-color: #f9df64;

.ant-btn{
  border-radius: 20px;
  display: flex;
  align-items: center;
  padding: 8px 42px;
  height: 40px;
  justify-content: center;
  font-weight: bold;
  font-size: 14px;

  &.rounded {
    border-radius: 8px;
  }

  svg{
    margin-right: 8px;
  }

  &.ant-btn-sm{
    padding: 8px 16px;
    min-width: 101px;
  }
}

.ant-btn-default{
  border-color: @primary-color;
  &:hover{
    color: @primary-color;
  }
}

.ant-btn-primary{
  color: #232d3b;
  border-color: @primary-color;
  background: @primary-color;
}
