@screen-md: 768px;

#insight-articles-section {
  min-height: 100vh;

  .articles-top-wrapper {
    margin-bottom: 30px;

    .section-title {
      font-size: 24px;
      font-weight: 700;
    }
  }

  .data-list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 15px;
    margin-bottom: 30px;
  }

  .pagination-wrapper {
    text-align: center;
    margin-top: 50px;
  }

  @media only screen and (max-width: @screen-md) {
    .data-list {
      grid-template-columns: 1fr;
    }
  }
}
