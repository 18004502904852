@secondary-color: #ff00ae;
@screen-xs: 480px;
@primary-color: #fedf47;
@text-color: #232d3b;

.vui-calendar-reschedule {
  border-radius: 8px !important;

  .ant-picker-cell {
    padding: 5px 0;

    &.ant-picker-cell-disabled {
      color: rgba(0, 0, 0, 0.4);
    }
  }

  .ant-picker-cell-inner {
    min-width: 34px;
    height: 34px;
    line-height: 34px;
    border-radius: 4px;
  }
  .ant-picker-calendar-date {
    .event-date {
      height: 100%;
      .available-schedule,
      .selected-date {
        z-index: -1;
        top: 0;
        position: absolute;
        height: 100%;
        width: 100%;
        border-radius: 4px;
      }

      .selected-date {
        color: black;
        z-index: 0;
        background-color: @primary-color;
      }
    }
  }

  .ant-picker-panel {
    border: none;
    border-radius: 8px;

    .ant-picker-content {
      th {
        font-weight: bold;
        color: #878787;
        padding-bottom: 10px;
      }
    }
  }

  .events .ant-badge-status {
    width: 100%;
    overflow: hidden;
    font-size: 12px;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .notes-month {
    font-size: 28px;
    text-align: center;
  }
  .notes-month section {
    font-size: 28px;
  }

  .ant-badge-status-dot {
    color: @secondary-color;
  }

  .ant-picker-calendar-header {
    margin-bottom: 5px;
    padding: 18px 20px;

    .ant-picker-calendar-header-content {
      flex: 1;
      display: flex;
      justify-content: space-between;
    }

    .ant-divider {
      margin: 12px 0 0 0;
    }
  }

  .ant-picker-cell-disabled {
    &::before {
      background: none;
    }

    &.ant-picker-cell-selected {
      .ant-picker-cell-inner {
        color: rgba(0, 0, 0, 0.4);
      }
    }
  }

  .ant-picker-cell-selected {
    .ant-picker-cell-inner {
      color: #232d3b;
      background: none;
    }
  }

  .ant-picker-cell-inner {
    &.ant-picker-calendar-date-today {
      &::before {
        border-radius: 0;
        border: none;
        border-bottom: 1px solid @secondary-color;
      }
    }
  }

  &.ant-picker-calendar-full
    .ant-picker-panel
    .ant-picker-cell
    .ant-picker-calendar-date
    .ant-picker-calendar-date-content {
    height: 35px;
  }

  &.ant-picker-cell-inner .ant-picker-cell-selected {
    .ant-picker-calendar-date {
      background-color: @primary-color;
      .ant-picker-calendar-date-value {
        color: @text-color;
      }
      .event-date {
        opacity: 0;
      }
    }
  }

  .ant-radio-button-wrapper {
    height: 40px;
    line-height: 38px;
    padding: 0 16px;
    &:first-child {
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
    }
    &:last-child {
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
    }
    &.ant-radio-button-wrapper-checked {
      border-color: @secondary-color !important;
      color: @secondary-color;
      &:before {
        background-color: @secondary-color;
      }
    }
    &:hover {
      color: @secondary-color;
    }
  }

  @media only screen and (max-width: @screen-xs) {
    .ant-picker-calendar-header {
      display: flex;
    }
  }
}
