@secondary-color: #ff00ae;
@third-color: #67eae5;
@screen-xs: 480px;
@primary-color: #fedf47;
@text-color: #232d3b;

#app-book-counseling-page {
  padding: 40px 15px;
  line-height: 1.5;

  .book-counseling-steps-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 30px;
    margin-bottom: 60px;

    .ant-steps {
      width: auto;
      min-width: 75%;

      .ant-steps-item-container .ant-steps-item-content .ant-steps-item-title {
        line-height: 22px;
      }
    }
  }

  .book-counseling-content-wrapper {
    .highlight {
      background-color: @primary-color;
      border-radius: 8px;
      padding: 0.5em 0.9em;
      font-size: 0.9em;
      font-weight: 600;
    }

    .has-stab {
      &::before {
        background-color: @primary-color;
      }
    }

    .book-counseling-content-title {
      font-family: "Century Gothic", sans-serif;
      font-size: 32px;
      font-weight: 700;
      margin-bottom: 50px;
      text-align: center;

      &.small-margin {
        margin-top: 15px;
        margin-bottom: 25px;
      }
    }

    .book-counseling-content-button-step {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: center;
      column-gap: 20px;

      .btn-secondary-shape {
        flex: 0 1 auto;
      }
    }
  }

  .form-group {
    label {
      display: block;
      color: #232d3b;
      font-size: 13px;
      font-weight: 700;
      font-family: "Century Gothic", sans-serif;
      margin-bottom: 8px;
    }

    .form-text {
      font-weight: 400;
      font-size: 15px;
    }

    .ant-select {
      width: 100%;
    }

    .ant-radio-group {
      display: flex;

      .ant-radio-wrapper {
        span {
          font-weight: 400;
        }
      }
    }
  }

  #book-counseling-content-patient {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  #book-counseling-content-schedule {
    .form-group {
      margin-bottom: 30px;

      .book-counseling-type-radio-group {
        // justify-content: center;
      }
    }

    .package-option-wrapper {
      border: 1px solid #e0e2e3;
      border-radius: 10px;
      padding: 18px 38px;
      text-align: center;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      cursor: pointer;
      height: 100%;

      @media only screen and (max-width: @screen-xs) {
        margin-bottom: 15px;
      }

      .package-option-radio-wrapper {
        position: absolute;
        left: 10px;
        top: 2px;
      }

      &.disabled {
        cursor: not-allowed;
      }
    }

    .vui-calendar-reschedule {
      border: 1px solid #f0f0f0;
      border-radius: 10px;

      .event-date {
        height: 100%;
        .available-schedule {
          height: 100%;
          text-align: center;
          color: @third-color;
          background: @third-color;
        }
      }

      .ant-picker-calendar-header {
        justify-content: center;
        margin-bottom: 5px;
      }

      &.ant-picker-calendar-full
        .ant-picker-panel
        .ant-picker-calendar-date-today {
        background-color: transparent !important;
        border-color: @secondary-color;
      }

      &.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected {
        .ant-picker-calendar-date {
          background-color: @primary-color !important;

          .ant-picker-calendar-date-value {
            color: @text-color;
          }
          .event-date {
            opacity: 0;
          }
        }
      }

      &.ant-picker-calendar-full .ant-picker-panel {
        .ant-picker-calendar-date {
          position: relative;

          .available-schedule {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            z-index: -1;
            overflow: hidden;
          }
        }
      }

      @media only screen and (max-width: @screen-xs) {
        .ant-picker-calendar-header {
          display: flex;
        }
      }
    }

    .schedule-helpers {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 30px;
      margin-top: 20px;

      .schedule-helper {
        display: flex;
        align-items: center;
        gap: 12px;
        font-size: 15px;
        font-weight: 400;

        .bullet {
          width: 14px;
          height: 14px;
          background-color: @third-color;
          border-radius: 50%;

          &.chosen {
            background-color: @primary-color;
          }
        }

        .line {
          width: 30px;
          height: 2.5px;
          background-color: @secondary-color;

          &.available {
            background-color: @third-color;
          }
        }
      }
    }

    .schedule-info {
      font-family: "Century Gothic", sans-serif;
      font-style: italic;
      color: #232d3b;
      font-size: 13px;
      font-weight: 400;
      margin-top: 20px;
    }

    .schedule-information-detail {
      margin-top: 40px;
    }
  }

  .psychologists-list-wrapper {
    text-align: center;
    .psychologists-list {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 40px;
      margin-bottom: 60px;
    }
  }

  @media only screen and (max-width: 991px) {
    .psychologists-list-wrapper {
      .psychologists-list {
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 25px;
      }
    }
  }

  @media only screen and (max-width: 767px) {
    .psychologists-list-wrapper {
      .psychologists-list {
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 25px;
      }
    }
  }

  .book-counseling-content-button-step {
    margin-top: 60px;

    .btn-secondary-shape {
      &:not(:first-child) {
        margin-left: 25px;
      }
    }
  }

  .other-patient-content {
    padding-top: 50px;
    margin-top: 50px;
    border-top: 1px solid #edeeef;

    .other-patient-content-choice {
      margin-bottom: 30px;
    }
  }

  .patient-content-form-layout {
    padding: 0 20px;

    .default-patient-not-complete {
      margin-top: 30px;
      margin-bottom: 30px;
      border-top: 1px solid #edeeef;
      padding-top: 30px;
      font-family: "Century Gothic", sans-serif;
      font-weight: 700;
      color: #000000;
    }
  }

  .default-patient-avatar {
    display: flex;
    align-items: center;
    margin-bottom: 24px;

    .ant-avatar {
      min-width: 60px;
      margin-right: 15px;
    }

    .default-patient-avatar-button-wrapper {
      flex-grow: 1;
      display: flex;
      flex-wrap: nowrap;
      width: 100%;
      justify-content: space-between;

      .default-patient-avatar-upload {
        width: 50%;
        margin-right: 15px;

        .ant-upload {
          width: 100%;
        }
      }

      .default-patient-avatar-remove-upload {
        width: 50%;
      }
    }
  }

  .default-patient-avatar-required {
    color: #ff4d4f;
    margin-top: 4px;
    text-align: left;
    font-size: 15px;
    line-height: 1.5715;
    margin-top: -10px;
    margin-bottom: 24px;
  }

  .default-patient-action-btn {
    max-width: 30%;
    display: block;
    margin: auto;
    margin-top: 50px;
  }

  .preview-booking-wrapper {
    margin: 0 auto 20px;
    display: flex;
    gap: 30px;

    .preview-booking-card {
      text-align: center;

      img {
        width: 100%;
        max-width: 300px;
      }

      .card-title {
        font-weight: 700;
        font-size: 15px;
        margin-top: 16px;
        margin-bottom: 8px;
      }

      .card-subtitle {
        font-size: 14px;
        color: #fc3f93;
        p {
          margin-bottom: 0;
        }
      }
    }

    .preview-booking-left,
    .preview-booking-right {
      min-width: 50%;
    }

    .vui-form-text-wrapper {
      margin-bottom: 20px;

      .vui-form-text-label {
        margin-bottom: 5px;
      }
    }
  }

  .preview-booking-price {
    font-family: "Century Gothic", sans-serif;
    font-weight: 700;
    margin-top: 40px;
    display: flex;
    justify-content: center;
    .preview-booking-price-wrapper {
      background-color: #f4fcfc;
      padding: 13px 22px;
      border-radius: 10px;
      display: flex;
      justify-content: left;

      .preview-booking-price-text {
        font-size: 17px;
        color: #000000;
        margin: 6px 15px 5px 0;
      }

      .preview-booking-price-value {
        color: @secondary-color;
        font-size: 25px;
        font-weight: bold;
      }

      .preview-booking-price-notes {
        font-size: 9px;
        color: #69707a;
      }
    }
  }

  .preview-booking-action {
    display: flex;
    justify-content: space-between;
    margin-top: 60px;

    .ant-spin-nested-loading {
      width: calc(50% - 8px);

      &:first-child {
        margin-right: 15px;
      }
    }
  }

  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .preview-booking-right {
      min-width: 45%;
    }
  }

  @media only screen and (max-width: 767px) {
    padding: 20px 15px;

    .book-counseling-title {
      width: 80%;
    }

    .row-smaller-mobile {
      margin-left: -15px !important;
      margin-right: -15px !important;

      .ant-col {
        padding-left: 15px !important;
        padding-right: 15px !important;
      }
    }

    .ant-form {
      .ant-form-item {
        .ant-form-item-label {
          label {
            font-size: 13px;
          }
        }

        .ant-input,
        .ant-picker-input > input {
          font-size: 13px;
        }

        .ant-radio-wrapper {
          span.ant-radio + * {
            font-size: 13px;
          }
        }
      }
    }

    .ant-select-single {
      .ant-select-selection-placeholder,
      .ant-select-selection-item {
        font-size: 13px;
      }
    }

    .book-counseling-steps-wrapper {
      margin-bottom: 30px;
    }

    .book-counseling-content-wrapper {
      .book-counseling-content-title {
        font-size: 20px;
        margin-bottom: 30px;

        &.small-margin {
          margin-bottom: 15px;
        }
      }
    }

    #book-counseling-content-schedule {
      .schedule-helpers {
        flex-direction: column;
        align-items: flex-start;
        gap: 15px;
      }
    }

    .patient-content-form-layout {
      padding: 0;

      .default-patient-not-complete {
        font-size: 13px;
      }
    }

    .default-patient-avatar {
      .ant-avatar {
        width: 50px !important;
        height: 50px !important;
        min-width: 50px;
      }

      .default-patient-avatar-button-wrapper {
        .ant-btn {
          font-size: 12px !important;
          padding: 6px !important;
          height: 35px;
        }
      }
    }

    .other-patient-content {
      padding-top: 30px;
      margin-top: 30px;
    }

    .default-patient-action-btn {
      max-width: 100%;

      .ant-btn {
        font-size: 13px;
      }
    }

    .preview-booking-wrapper {
      flex-direction: column;

      .vui-form-text-wrapper {
        .vui-form-text-label {
          font-size: 13px;
        }

        .vui-form-text-value {
          font-size: 15px;
        }
      }
    }

    .preview-booking-price {
      margin-top: 0;
      text-align: left;
    }

    .preview-booking-action {
      flex-direction: column;
      gap: 15px;
      margin-top: 50px;

      .ant-spin-nested-loading {
        width: 100%;

        .ant-btn {
          font-size: 13px;
        }
      }
    }
  }
}
