@secondary-color: #ff00ae;
@primary-color: #FEDF47;

.vui-checkbox{
  &.ant-checkbox-wrapper{
    display: flex;
    align-items: center;
    .ant-checkbox{
      top: 0;
      margin-right: 12px;
      .ant-checkbox-inner{
        width: 20px;
        height: 20px;
        border-radius: 4px;
      }
      & + span{
        padding-left: 0;
        padding-right: 0;
      }
    }
  }

  &.is-primary{
    .ant-checkbox{
      &:hover, &:focus{
        .ant-checkbox-inner{
          border-color: @primary-color;
        }
      }
      &.ant-checkbox-checked .ant-checkbox-inner{
        background-color: @primary-color;
        border-color: @primary-color;
      }
    }
    &:hover{
      .ant-checkbox-inner{
        border-color: @primary-color;
      }
    }
  }

  &.is-secondary{
    .ant-checkbox{
      &:hover, &:focus{
        .ant-checkbox-inner{
          border-color: @secondary-color;
        }
      }
      &.ant-checkbox-checked .ant-checkbox-inner{
        background-color: @secondary-color;
        border-color: @secondary-color;
      }
    }
    &:hover{
      .ant-checkbox-inner{
        border-color: @secondary-color;
      }
    }
  }
}
