@secondary-color: #ff00ae;
@third-color: #fc3f93;
@fourth-color: #000000;
@five-color: #f3d230;
@screen-md: 768px;
@primary-color: #fedf47;
@default-border-radius: 8px;

.patient-card {
  min-height: 113px;
  background-color: transparent;
  position: relative;
  border: 1px solid #e0e2e3;
  border-radius: 10px;
  text-align: left;
  display: flex;
  align-items: center;
  padding: 0px 20px 0px 40px;
  cursor: pointer;

  .patient-card-image {
    width: 35% !important;
    border-top-left-radius: 10px !important;
    border-top-right-radius: 10px !important;
    margin: 0;
  }
  .patient-card-name {
    font-weight: bold;
    font-size: 16px;
    font-weight: 700;
    color: @fourth-color;
    margin-bottom: 8px;
  }
  .patient-card-title {
    color: @third-color;
    font-weight: 400;
    font-size: 14px;
    margin-bottom: 2px;

    p,
    span,
    strong,
    i,
    div,
    a,
    small {
      margin: 0;
    }
  }

  .patient-card-email {
    font-size: 13px;
    color: #69707a;
    margin-bottom: 0;
  }

  .complete-profile-btn {
    font-size: 11px;
    font-weight: bold;
    text-decoration: underline;
    z-index: 3;
    position: relative;
    color: @secondary-color;
  }

  .patient-card-content-wrapper {
    display: flex;
    align-items: center;
    gap: 10px;
    width: 100%;

    .ant-tag {
      border-radius: 20px;
    }
  }

  .patient-card-radio {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;

    .ant-radio-wrapper {
      width: 100%;
      height: 100%;
      padding-top: 10px;
      padding-left: 10px;
      text-align: left;
    }

    .ant-radio.ant-radio-checked:after {
      background: transparent !important;
    }
  }

  &:hover {
    transition: all 500ms;
    box-shadow: 0 8px 20px rgba(2, 10, 49, 0.16);
  }
}
