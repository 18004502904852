@primary-color: #fedf47;
@screen-md: 768px;

.assessment-package {
  border-radius: 8px;
  position: relative;
  transition: all 500ms;
  text-align: center;
  height: 100%;
  &:hover {
    box-shadow: 0 2px 8px rgba(2, 10, 49, 0.16);
    .assessment-package-image {
      transform: scale(1.1);
    }
  }

  .ant-card-body {
    padding: 10px 30px;
  }

  .assessment-package-wrapper {
    display: flex;
    justify-content: center;

    .assessment-package-radio-wrapper {
      position: absolute;
      left: 10px;
      top: 10px;
    }

    .assessment-package-image {
      transition: all 500ms;
      width: 100%;
      height: 215px;
      max-width: 289px;
      object-fit: contain;
    }
  }

  @media only screen and (max-width: @screen-md) {
    .assessment-package-wrapper {
      .assessment-package-image {
        width: 100%;
        height: auto;
      }
    }
  }
}
