@screen-md: 768px;
@secondary-color: #ff00ae;

.vui-pagination{
  .ant-pagination-prev, .ant-pagination-next{
    min-width: 40px;
    height: 40px;
    .ant-pagination-item-link{
      border-radius: 50%;
      background-color: @secondary-color;
      color: white;
      border: 0;
      display: flex;
      font-size: 14px;
      align-items: center;
      justify-content: center;
    }
  }

  .ant-pagination-item {
    border: 0;
    &:hover a, &.ant-pagination-item-active a{
      color: @secondary-color;
      font-weight: bold;
    }
  }
}
