@default-border-radius: 8px;
@screen-md: 768px;
@screen-xs: 321px;

.billing-information-card {
  border: 1px solid #e0e2e3;
  border-radius: @default-border-radius;
  padding: 20px;
  margin-bottom: 16px;
  .vui-form-text-wrapper {
    margin-bottom: 0;
  }

  .billing-information-card-content-container {
    &.multiple-booking {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      // align-items: center;
      gap: 20px;
    }
  }

  .billing-information-card-content-wrapper {
    padding-bottom: 30px;
    margin-bottom: 30px;
    border-bottom: 1px solid #e0e2e3;

    &.last {
      padding: 0;
      border-bottom: none;
      margin: 0;
    }
  }

  .billing-information-card-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 10px;
    text-align: right;

    .ant-typography {
      margin-bottom: 0;
    }

    .billing-information-tag-wrapper {
      display: flex;
      gap: 6px;
    }

    .booking-code-title {
      font-size: 10px;
    }

    .booking-code {
      font-size: 13px;
      color: #c6c8cc;
    }
  }

  .billing-information-card-wrapper {
    display: flex;
    justify-content: space-between;
    gap: 24px;

    .billing-information-card-data {
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      grid-gap: 20px;
      flex: 0.85;
    }

    .billing-information-action-wrapper {
      display: flex;
      flex-direction: column;
      gap: 22px;
      justify-content: center;

      .billing-information-attachment-wrapper {
        display: flex;
        gap: 18px;
        .billing-information-attachment-btn {
          text-align: center;
        }
      }
    }
  }

  @media only screen and (max-width: @screen-md) {
    padding: 15px;

    .billing-information-card-content-container {
      &.multiple-booking {
        display: flex;
        flex-direction: column;
        column-gap: 20px;
        row-gap: 20px;

        .billing-information-card-wrapper {
          .billing-information-card-data {
            border-bottom: none;
            padding-bottom: 0px;
          }
        }

        .billing-information-action-wrapper {
          width: 100%;
          .ant-spin-nested-loading {
            width: 100%;
          }
        }
      }
    }

    .billing-information-card-header {
      .billing-information-tag-wrapper {
        align-items: center;
      }

      .booking-tag-title,
      .booking-code {
        font-size: 9px;
      }

      .counseling-tag,
      .status-tag,
      .booking-code-title {
        font-size: 7px;
      }
    }

    .billing-information-card-wrapper {
      flex-direction: column;
      align-items: unset;

      .billing-information-card-data {
        grid-template-columns: repeat(1, 1fr);
        border-bottom: 1px solid #edeeef;
        padding-bottom: 16px;
      }

      .billing-information-action-wrapper {
        flex-direction: column-reverse;
        align-items: center;

        .ant-spin-nested-loading {
          width: 100%;
        }
      }
    }
  }

  @media only screen and (max-width: @screen-xs) {
    padding: 15px;

    .billing-information-card-header {
      .booking-tag-title,
      .booking-code {
        font-size: 7px;
      }

      .counseling-tag,
      .status-tag,
      .booking-code-title {
        font-size: 5px;
      }
    }
  }
}
