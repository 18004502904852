@secondary-color: #ff00ae;
@screen-md: 768px;

.list-text-modal-wrapper{
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 6px;

  .list-text-label, .list-text-value{
    margin-bottom: 0;
    font-size: 14px;
  }

  .list-text-value{
    max-width: 60%;
    text-align: right;
  }

  a{
    color: @secondary-color;
    font-size: 14px;
  }

  @media only screen and (max-width: @screen-md){
    margin-bottom: 16px;
    display: block;
    .list-text-label{
      margin-bottom: 4px;
    }

    &.only-flex{
      display: flex;
    }
  }
}
