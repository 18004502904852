.ant-modal-content{
  .ant-modal-body{
    .ant-checkbox-wrapper{
      align-items: center;
      margin-bottom: 16px;
      .ant-checkbox{
        &:hover, &:focus{
          .ant-checkbox-inner{
            border-color: #ff00ae;
          }
        }
        &.ant-checkbox-checked .ant-checkbox-inner{
          background-color: #ff00ae;
          border-color: #ff00ae;
        }
      }
      &:hover{
        .ant-checkbox-inner{
          border-color: #ff00ae;
        }
      }
    }

    .term-and-condition-list {
      margin: 10px 26px 0 0;
      padding-left: 18px;
      li {
        margin-bottom: 8px;
        text-align: justify;
      }
    }
  }
}
