@text-color: #232d3b;

.how-to-pay-modal{
  .ant-collapse{
    &.ant-collapse-borderless{
      background-color: transparent;
    }
    .ant-collapse-item{
      .ant-collapse-header{
        padding-left: 0;
        padding-right: 0;
        .ant-collapse-header-text{
          font-weight: bold;
        }
      }

      .ant-collapse-content{
        .ant-collapse-content-box{
          padding: 10px 0;
          .panel-list{
            padding-left: 1rem;
            li{
              margin-bottom: 4px;
            }
          }
        }
      }

      .ant-collapse-expand-icon{
        .expand-icon-wrapper{
          width: 28px;
          height: 28px;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: @text-color;
          border-radius: 50%;
          right: 0;
          svg{
            width: 12px;
            height: 12px;
            path{
              fill: white;
            }
          }
        }
      }
    }
  }
}
