@screen-md: 768px;
@secondary-color: #ff00ae;

#app-psychologists-page {
  padding: 40px 0;
  line-height: 1.5;
  .psychologists-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .psychologists-filter-wrapper {
      .vui-select-single {
        min-width: 250px;
        .ant-select-selector {
          background-color: #eafffe;
        }
        .ant-select-clear {
          background-color: #eafffe;
        }
        .ant-select-selection-placeholder {
          color: black;
          font-weight: 700;
        }
      }
    }
    .ant-select-multiple {
      max-width: 160px;
      .ant-select-selection-placeholder,
      .ant-select-arrow {
        color: #232d3b;
        font-weight: bold;
      }
    }
  }

  .search-wrapper {
    max-width: 380px;
  }

  .psychologists-list-wrapper {
    text-align: center;
    .psychologists-list {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 40px;
      margin-bottom: 60px;
    }
  }

  @media only screen and (max-width: 991px) {
    .psychologists-list-wrapper {
      .psychologists-list {
        grid-template-columns: repeat(2, 1fr);
      }
    }
  }

  @media only screen and (max-width: @screen-md) {
    .psychologists-list-wrapper {
      .psychologists-list {
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 25px;
      }
    }

    .psychologists-top {
      display: block;
      padding: 0 15px;
      margin-bottom: 25px;

      .psychologists-filter-wrapper {
        .vui-select-single {
          width: 250px;
        }
      }
    }

    .search-wrapper {
      padding: 0 15px;
      max-width: 285px;
    }
  }
}
