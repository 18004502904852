@screen-md: 768px;

#account-profile-body {
  .password-last-update {
    font-size: 13px;
    color: #878787;
  }

  @media only screen and (max-width: @screen-md) {
    padding: 20px 0 0;
  }
}
