@default-border-radius: 8px;
@default-border-color: #edeeef;
@default-background-color: #f6f7f7;
@screen-md: 768px;
@success-color: #48d581;

.print-billing-invoice-package {
  .print-billing-invoice-package-wrapper {
    position: relative;;
    padding: 0px 60px 60px 60px;
  }
  .billing-invoice-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;

    .logo-wrapper {
      max-width: 150px;
    }
    .billing-invoice-header-title {
      font-size: 20px;
      line-height: 15px;
    }
  }

  .billing-invoice-section-title {
    background-color: @default-background-color;
    padding: 6px 0 6px 12px;
    font-size: 13px;
    font-weight: 700;
    line-height: 20px;
  }

  .billing-invoice-order-detail {
    display: flex;
    gap: 90px;
  }

  .billing-invoice-payment {
    display: flex;
    justify-content: space-between;
  }

  .billing-invoice-status {
    margin-top: 15px;
    border: 4px solid @success-color;
    border-radius: @default-border-radius;
    max-width: 140px;
    margin-left: auto;
    text-align: center;
    padding: 0 15px;
    font-size: 24px;
    justify-content: center;
    text-transform: uppercase;
    color: darken(@success-color, 10%);
    font-weight: bold;
    display: flex;
    align-items: center;
    svg {
      margin-right: 10px;
      path {
        fill: @success-color;
      }
    }
  }

  .billing-invoice-footer {
    display: flex;
    width: 100%;
    page-break-before: auto;
    page-break-after: auto;
    page-break-inside: avoid;
    justify-content: space-between;
    align-items: center;
    margin-top: 30px;
    font-size: 12px;

    p {
      margin: 0;
    }

    .billing-invoice-footer-right {
      margin-right: 5px;
      text-align: end;

      img {
        height: 50px;
        width: auto;
      }

      .signature-name {
        font-weight: bold;
        text-decoration: underline;
      }
    }
    .billing-invoice-footer-title {
      font-weight: bold;
    }
  }

  .page-break {
    page-break-before: auto;
    page-break-after: auto;
    page-break-inside: avoid;
  }

  hr {
    height: 1px;
    background-color: @default-border-color;
    border: none;
  }

  .billing-invoice-footer-wave {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 60px;
    page-break-after: always;
  }
}
