#auth-reset-password-page{
  .auth-reset-password-wrapper{
    text-align: center;
    .auth-reset-password-title{
      font-family: "helsinki", sans-serif;
      font-size: 24px;
      text-align: center;
    }
  }
}
