@screen-md: 768px;
@default-border-radius: 8px;
@lighter-tosca: #f4fcfc;

#app-cart-page {
  padding: 40px 0;
  line-height: 1.5;
  .cart-steps-wrapper {
    max-width: 580px;
    margin: 24px auto 24px;
  }

  .cart-content-wrapper {
    margin-top: 64px;
    .cart-no-content {
      max-width: 680px;
      margin: auto;
    }
    .cart-content {
      display: flex;
      gap: 40px;
      .card-list {
        flex: 0.7;
        .vui-form-card {
          &:not(:last-child) {
            margin-bottom: 20px;
          }
        }
      }
      .cart-summary {
        flex: 0.3;
        padding: 24px 20px;
        border-radius: @default-border-radius;
        background-color: @lighter-tosca;
        box-shadow: 0 2px 4px rgba(2, 10, 49, 0.16);
        height: fit-content;
        position: sticky;
        top: 74px;
      }
    }
  }

  @media only screen and (max-width: @screen-md) {
    .vui-title-wrapper {
      padding-right: 15px;
      padding-left: 15px;
    }
    .cart-content-wrapper {
      padding: 0 15px;
      .cart-content {
        flex-direction: column;
      }

      .cart-summary {
        position: unset;
      }
    }
  }
}
