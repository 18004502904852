@secondary-color: #ff00ae;
@default-border-radius: 10px;

.vui-select-single{
  font-size: 16px;

  &.secondary-type&:not(.ant-select-disabled):hover .ant-select-selector{
    border-color: @secondary-color;
  }

  &:not(.ant-select-customize-input) .ant-select-selector{
    border-radius: @default-border-radius;
    padding: 0 16px;
    height: 40px;
    .ant-select-selection-item{
      line-height: 38px;
    }
    .ant-select-selection-search-input{
      height: 40px;
    }
  }

  &.secondary-type&.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-color: @secondary-color;
    box-shadow: none;
    border-right-width: 1px;
    outline: 0;
  }
}
