@screen-md: 768px;
@primary-color: #FEDF47;
@secondary-color: #ff00ae;
@lighter-tosca: #F4FCFC;

#app-service-detail-page{
  overflow: hidden;
  padding: 40px 0;

  .hide-desktop{
    display: none;
  }

  .hide-mobile{
    display: block;
  }

  .ant-typography {
    * {
      font-family: "Century Gothic", sans-serif !important;
    }
  }


  .custom-tabs-header{  
    .ant-tabs-nav{
      display: none;
    }
  }

  .custom-tab-wrapper{
    margin-left: -30px;
    margin-right: -30px;
    margin-bottom: 20px;

    .swiper{
      padding-left: 35px;
      padding-right: 35px;

      .swiper-slide{
        width: auto;
      }
    }

    .swiper-button-prev{
      left: 5px;
    }

    .swiper-button-next{
      right: 5px;
    }

    .swiper-button-prev, .swiper-button-next{
      top: 70%;
      width: 25px;
      height: 25px;

      &::after{
        font-size: 12px;
      }
    }
   
    .custom-tab-item{
      padding: 8px 14px;
      transition: all 0.2s;
      filter: grayscale(1);
      display: inline-flex;
      align-items: center;
      cursor: pointer;

      .custom-tab-item-image{
        width: 24px;
        margin-right: 10px;
      }

      &.active-tab{
        background-color: @lighter-tosca;
        border-radius: 8px;
        filter: grayscale(0);
        color: @secondary-color;
      }

      &:hover{
        color: @secondary-color;
        background-color: @lighter-tosca;
        border-radius: 8px;
        filter: grayscale(0);
      }
    }
  }

  .service-info-section{
    margin: 20px 0 40px;
    .service-info-left{
      .service-info-title{
        font-size: 24px;
        font-weight: bold;
      }
    }
    .service-info-right{
      margin: auto;
      width: 100%;
      max-width: 550px;
    }
  }

  .service-type{
    padding: 60px 0 20px;
    overflow: unset !important;

    .service-type-title{
      font-size: 24px;
      font-weight: bold;
    }
    
    .has-stab {
      &:before {
        background-color: @primary-color;
      }
    }

    .ant-tabs{
      &.ant-tabs-top > .ant-tabs-nav::before{
        border-bottom: 0;
      }

      .ant-tabs-nav-wrap{
        .ant-tabs-nav-list{
          .ant-tabs-ink-bar{
            display: none;
          }

          .ant-tabs-tab{
            padding: 8px 14px;
            &+ .ant-tabs-tab {
              margin: 0 0 0 16px;
            }
            transition: all 0.2s;
            filter: grayscale(1);
            .tab-pane-nav{
              display: flex;
              align-items: center;
              .tab-pane-nav-image{
                width: 24px;
                margin-right: 10px;
              }
            }
            &.ant-tabs-tab-active{
              .ant-tabs-tab-btn{
                color: @secondary-color;
              }
              background-color: @lighter-tosca;
              border-radius: 8px;
              filter: grayscale(0);
            }
            &:hover{
              color: @secondary-color;
              background-color: @lighter-tosca;
              border-radius: 8px;
              filter: grayscale(0);
            }
          }
        }
      }
    }

    &.area-expertise{
      padding: 0 0 60px;
      .has-stab {
        &:before {
          background-color: @primary-color;
        }
      }

    }
  }

  @media only screen and (max-width: @screen-md){
    .service-info-section{
      margin: 40px 0 20px;
      padding: 0 15px;
    }

    .service-type{
      padding: 20px 15px 20px;
      &.area-expertise{
        padding: 0 15px 40px;
      }
    }
  }

  @media only screen and (min-width: 768px) and (max-width: 1199px) {
    .custom-tab-wrapper{
      margin-left: 0;
      margin-right: 0;
    }
  }

  @media only screen and (max-width: 767px) {
    .hide-desktop{
      display: block;
    }

    .hide-mobile{
      display: none;
    }

    .custom-tab-wrapper{
      margin-left: 0;
      margin-right: 0;

      .ant-select{
        width: 100%;

        .ant-select-selector{
          background: #F4FCFC;
          border: 0;

          .ant-select-selection-item{
            .vui-custom-with-image{
              span{
                color: #FC3F93;
              }
            }
          }
        }
      }
    }
  }
}
