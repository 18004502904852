@screen-md: 768px;

.vui-secondary-title-wrapper {
  margin-bottom: 25px;
  .vui-secondary-title {
    font-family: "helsinki", sans-serif;
    display: inline;
    margin-bottom: 15px;
    color: #232D3B;
    position: relative;
    font-weight: 400;
  }
}
