// ant input component
.ant-input,
.ant-input-password,
.ant-input-affix-wrapper,
.ant-picker {
  border-radius: 8px;
  padding: 8px 12px;
  width: 100%;

  &.ant-input-affix-wrapper-status-error {
    .ant-input-suffix {
      .anticon {
        color: #ff4d4f;
      }
    }
  }
}

.ant-input-number {
  border-radius: 8px;
  padding: 4.5px 0;
  width: 100%;
}

// ant select component
.ant-select-dropdown {
  border-radius: 8px;
  .ant-select-item-option-content {
    margin-right: 10px;
    padding: 6px 0;
    align-items: center;
  }
  &.dropdown-secondary-type {
    .ant-select-item-option-selected {
      background-color: transparent;
      color: @secondary-color;
      svg {
        fill: @secondary-color;
      }
    }
  }
  .ant-select-item {
    padding: 6px 20px;
  }
  &.ant-select-dropdown-placement-bottomLeft {
    margin-top: 10px;
  }
}

.ant-select-single {
  .ant-select-selection-placeholder {
    display: flex;
    align-items: center;
  }
}

// ant radio component
.ant-radio {
  border-color: @secondary-color;
  &.ant-radio-checked {
    &:after {
      border: 1px solid @secondary-color;
    }
    .ant-radio-inner {
      border-color: @secondary-color;
      &:after {
        background-color: @secondary-color;
      }
    }
  }
  &:hover {
    .ant-radio-inner {
      border-color: @secondary-color;
    }
  }
}

// swiper
.swiper-pagination {
  display: flex;
  align-items: center;
  justify-content: center;

  .swiper-pagination-bullet {
    transition: all 0.5s;

    &.swiper-pagination-bullet-active {
      width: 25px;
      background-color: @secondary-color;
      border-radius: 20px;
    }
  }

  &.swiper-pagination-bullets {
    position: relative;
    display: inline-flex;
    width: unset;
    left: 50%;
    transform: translateX(-50%);

    &::before,
    &::after {
      position: absolute;
      color: #232d3b;
      font-size: 13px;
      font-weight: 400;
      font-family: "Century Gothic", sans-serif;
    }

    &::before {
      left: -20px;
      content: attr(data-before);
    }

    &::after {
      right: -20px;
      content: attr(data-after);
    }
  }
}
.swiper-button-next,
.swiper-button-prev {
  background-color: @secondary-color;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  &:after {
    font-size: 14px;
    color: white;
  }
}

.swiper-button-next {
  right: 40px;
}

.swiper-button-prev {
  left: 40px;
}

// custom
.hide-mobile {
  display: block;
}

.hide-mobile-flex {
  display: flex;
}

.hide-desktop {
  display: none;
}

@media only screen and (max-width: @screen-md) {
  .hide-mobile {
    display: none;
  }

  .hide-mobile-flex {
    display: none;
  }

  .hide-desktop {
    display: block;
  }
}

.w-100 {
  width: 100%;
}

.link-text {
  color: @secondary-color;
  font-weight: 500;
  cursor: pointer;

  &.underline {
    text-decoration: underline;
    text-decoration-color: @secondary-color;
  }
}

.text-align-center {
  text-align: center;
}

.text-align-right {
  text-align: right;
}

img {
  width: 100%;
}

.vui-custom-with-image {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;

  img,
  svg {
    height: 24px;
    max-width: 24px;
    min-width: 24px;
    margin-right: 10px;
    object-fit: contain;
    object-position: center;
  }

  span {
    flex-grow: 1;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 100%;
  }
}

.antd-country-phone-input {
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
  .ant-input-group-addon {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }
}

.plain-btn {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  cursor: pointer;
  outline: inherit;
}

.ant-alert {
  &.alert-success {
    position: fixed;
    left: 0;
    right: 0;
    z-index: 2;

    .ant-alert-message {
      text-align: center;
      color: #48d581;
      font-weight: bold;
    }
  }

  &.alert-warning {
    border-radius: 6px;
    background-color: rgba(255, 245, 235, 1);
    border: none;
    .ant-alert-message {
      color: rgba(255, 166, 76, 1);
    }
  }

  &.unverified-phone-alert {
    position: fixed;
    max-width: fit-content;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 99;
    padding: 12px 33px;
    border-radius: 8px;
    top: 78px;
    background-color: rgba(255, 242, 240, 1);

    .ant-alert-message {
      color: @red-color;
    }
  }

  @media only screen and (max-width: @screen-md) {
    &.unverified-phone-alert {
      top: 58px;
    }
  }
}
