@pending-color: #FAA24B;
@canceled-color: #FF290B;
@paid-color: #48D581;

.vui-status-text{
  text-transform: capitalize;
  &.status-pending{
    color: @pending-color;
  }
  &.status-canceled{
    color: @canceled-color;
  }
  &.status-paid{
    color: @paid-color;
  }
}
