@screen-md: 768px;
@secondary-color: #ff00ae;
@third-color: #67eae5;
@screen-xs: 480px;
@primary-color: #fedf47;
@text-color: #232d3b;

.account-reschedule-page {
  padding: 60px 0;
  .reschedule-steps-wrapper {
    max-width: 320px;
    margin: 36px auto 46px;
  }
  .vui-secondary-title-wrapper {
    text-align: center;
    margin-bottom: 56px;
  }
  .ant-select-single {
    .ant-select-selector .ant-select-selection-placeholder {
      line-height: 36px;
    }
  }

  .schedule-content {
    .highlight {
      background-color: @primary-color;
      border-radius: 8px;
      padding: 0.5em 0.9em;
      font-size: 0.9em;
      font-weight: 600;
    }

    .has-stab {
      &::before {
        background-color: @primary-color;
      }
    }

    .schedule-content-title {
      font-family: "Century Gothic", sans-serif;
      font-size: 32px;
      font-weight: 700;
      margin-bottom: 50px;
      text-align: center;

      &.small-margin {
        margin-top: 15px;
        margin-bottom: 25px;
      }
    }

    .schedule-content-button-step {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: center;
      column-gap: 20px;

      .btn-secondary-shape {
        flex: 0 1 auto;
      }
    }

    .consultation-type-option-wrapper {
      border: 1px solid #e0e2e3;
      border-radius: 10px;
      padding: 18px 38px;
      text-align: center;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      cursor: not-allowed;
      background-color: #f6f7f7;
      height: 100%;

      .consultation-type-option-radio-wrapper {
        position: absolute;
        left: 10px;
        top: 2px;
      }

      .consultation-type-option-title {
        font-size: 16px;
      }

      .consultation-type-option-label {
        font-size: 13px;
      }

      @media only screen and (max-width: @screen-md) {
        margin-bottom: 15px;

        .consultation-type-option-title {
          font-size: 12px;
        }

        .consultation-type-option-label {
          font-size: 9px;
        }
      }
    }

    .vui-calendar-reschedule {
      border: 1px solid #f0f0f0;
      border-radius: 10px;

      .event-date {
        height: 100%;
        .available-schedule {
          height: 100%;
          text-align: center;
          color: @third-color;
          background: @third-color;
        }
      }

      .ant-picker-calendar-header {
        justify-content: center;
        margin-bottom: 5px;
      }

      &.ant-picker-calendar-full
        .ant-picker-panel
        .ant-picker-calendar-date-today {
        background-color: transparent !important;
        border-color: @secondary-color;
      }

      &.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected {
        .ant-picker-calendar-date {
          background-color: @primary-color !important;

          .ant-picker-calendar-date-value {
            color: @text-color;
          }
          .event-date {
            opacity: 0;
          }
        }
      }

      &.ant-picker-calendar-full .ant-picker-panel {
        .ant-picker-calendar-date {
          position: relative;

          .available-schedule {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            z-index: -1;
            overflow: hidden;
          }
        }
      }

      @media only screen and (max-width: @screen-xs) {
        .ant-picker-calendar-header {
          display: flex;
        }
      }
    }
  }

  .preview-booking-wrapper {
    max-width: 780px;
    margin: 0 auto 20px;
    display: flex;
    gap: 20px;
    .preview-booking-card {
      text-align: center;
      img {
        max-width: 316px;
      }
      .card-title {
        font-weight: bold;
        margin-top: 16px;
        margin-bottom: 8px;
      }
      .card-subtitle {
        font-size: 14px;
        p {
          margin-bottom: 0;
        }
      }
    }
    .vui-form-text-wrapper {
      .vui-form-text-label {
        margin-bottom: -2px;
      }
      margin-bottom: 12px;
    }
  }

  .reschedule-helpers {
    display: flex;
    align-items: center;
    gap: 30px;
    margin-top: 20px;
    margin-bottom: 40px;
    .reschedule-helper {
      display: flex;
      align-items: center;
      gap: 12px;
      font-size: 16px;
      .bullet {
        width: 14px;
        height: 14px;
        background-color: #67eae5;
        border-radius: 50%;
        &.chosen {
          background-color: #fedf47;
        }
      }
      .line {
        width: 30px;
        height: 2.5px;
        background-color: @secondary-color;
        &.available {
          background-color: #67eae5;
        }
      }
    }
  }

  @media only screen and (max-width: @screen-md) {
    padding: 40px 15px;
    .ant-select-single {
      max-width: unset;
    }
    .preview-booking-wrapper {
      flex-direction: column;
    }
  }
}
