@primary-color: #fedf47;
@screen-md: 768px;

#app-book-page {
  padding: 40px 15px;
  line-height: 1.5;

  .book-options-wrapper {
    text-align: center;
    .book-options-content-title {
      font-size: 32px;
      font-weight: 700;

      &.has-stab {
        &::before {
          background-color: @primary-color;
        }
      }
    }

    .book-type-radio-group {
      .book-option-wrapper {
        border-radius: 8px;
        position: relative;
        transition: all 500ms;

        &:hover {
          box-shadow: 0 2px 8px rgba(2, 10, 49, 0.16);
          .book-option-image {
            transform: scale(1.1);
          }
        }

        .ant-card-body {
          padding: 10px 30px;
        }

        .book-option-radio-wrapper {
          position: absolute;
          left: 10px;
          top: 10px;
        }

        .book-option-image {
          transition: all 500ms;
          width: 240px;
          height: 179px;
          object-fit: contain;
        }
      }
    }
  }

  @media only screen and (max-width: @screen-md) {
    .book-options-wrapper {
      .book-options-content-title {
        font-size: 26px;
      }

      .ant-card-body {
        padding: 10px 10px !important;
      }
    }
  }
}
