@screen-md: 768px;

.package-card-wrapper {
  border: 1px solid #e0e2e3;
  border-radius: 10px;
  padding: 18px 38px;
  text-align: center;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
  height: 100%;
  position: relative;
  background-color: white;

  .package-card-radio-wrapper {
    position: absolute;
    left: 10px;
    top: 2px;
  }

  .package-card-ribbon-wrapper {
    position: absolute;
    right: -12px;
    top: -10px;
    .package-card-ribbon {
      position: relative;

      .ribbon-left-part {
        position: absolute;
        top: 1;
        z-index: -1;
        left: 3px;
      }

      .ribbon-right-part {
        position: absolute;
        bottom: 0;
        bottom: 3px;
        right: 1px;
        z-index: -1;
      }

      .ribbon-text {
        position: absolute;
        transform: rotate(45deg);
        font-size: 0.8em;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        top: -5px;
        left: 5px;
        font-size: 9px;
        font-weight: bold;
      }
    }
  }

  &.disabled {
    cursor: not-allowed;
  }

  @media only screen and (max-width: @screen-md) {
    .package-card-subtitle {
      font-size: 9px !important;
    }
  }
}
