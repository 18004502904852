@grayscale-color: #a7abb1;
@screen-md: 768px;
@primary-color: #fedf47;

.cart-list-patient-wrapper {
  display: flex;
  align-items: flex-start;
  &.is-unavailable {
    .ant-typography,
    p {
      color: @grayscale-color;
    }
  }

  .description-wrapper {
    p {
      margin-bottom: 5px;
    }
  }

  .cart-list-patient-info {
    margin-left: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    .cart-list-info-right {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      text-align: right;

      .cart-list-info-right-price {
        font-weight: bold;
        margin-bottom: 0;
        text-wrap: nowrap;
      }
      .cart-list-info-right-icon {
        svg {
          width: 18px;
          height: 18px;
          fill: #69707a;
        }
      }
    }

    .cart-list-item-info {
      margin-bottom: 6px;
      font-size: 14px;
      &:first-child {
        margin-top: 6px;
      }
    }
    .list-text-modal-wrapper {
      .list-text-value {
        svg {
          width: 20px;
          height: 20px;
          fill: #69707a;
          cursor: pointer;
        }
      }
    }

    .assessment-img {
      width: 24px;
      height: 18px;
      object-fit: cover;
    }
  }

  .package-label {
    background-color: @primary-color;
    padding: 3px 12px;
    border-radius: 8px;
    font-size: 12px;
    margin-left: 10px;
    font-weight: 500;
  }

  .ant-collapse-header {
    gap: 10px;
    align-items: center !important;
    padding-left: 0 !important;
    .ant-collapse-expand-icon {
      order: 2;
      .expand-icon-wrapper {
        width: 20px;
        height: 20px;
        display: flex !important;
        align-items: center;
        justify-content: center;
        background-color: black;
        border-radius: 50%;
        right: 0;
        svg {
          width: 10px;
          height: 10px;
          path {
            fill: white;
          }
        }
      }
    }

    .ant-collapse-header-text {
      background-color: #eafffe;
      padding: 3px 12px;
      border-radius: 8px;
    }
  }

  @media only screen and (max-width: @screen-md) {
    .cart-list-patient-info {
      flex-direction: column;
      .cart-list-info-right {
        flex-direction: row;
        align-items: center;
      }
    }
  }
}
