@screen-md: 768px;

.event-list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 50px;

  @media only screen and (max-width: @screen-md){
    grid-template-columns: 1fr;
  }
}