.vui-search {
  border-radius: 100px;
  background-color: #f6f7f7;
  padding: 8px 12px;
  border-color: #f6f7f7;

  .ant-input {
    background-color: #f6f7f7;
  }

  .vui-search-action {
    border: none;
    background: transparent;
    cursor: pointer;
  }
}
