#auth-register-success-page{
  .auth-register-success-wrapper{
    text-align: center;
    .auth-register-success-title{
      font-family: "helsinki", sans-serif;
      font-size: 24px;
      text-align: center;
    }
  }
}
