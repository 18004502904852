@secondary-color: #ff00ae;
@screen-md: 768px;

.more-button-wrapper{
  margin-top: 24px;
  .ant-spin-nested-loading{
    width: fit-content;
    margin: auto;
    .ant-spin{
      color: @secondary-color;
      .ant-spin-dot-item{
        background-color: @secondary-color;
      }
    }
  }
}

.social-media-wall-tabs{
  .ant-tabs-nav-wrap{
    .ant-tabs-nav-list{
      .ant-tabs-ink-bar{
        display: none;
      }
      .ant-tabs-tab{
        .ant-tabs-tab-btn{
          font-size: 20px;
        }
        &.ant-tabs-tab-active, &:hover{
          .ant-tabs-tab-btn{
            color: @secondary-color;
            font-weight: bold;
          }
        }
      }
    }
  }
  &.ant-tabs-top > .ant-tabs-nav::before{
    border-bottom: 0;
  }

  @media only screen and (max-width: @screen-md){
    .ant-tabs-nav-wrap{
      justify-content: center;

      .ant-tabs-nav-list{
        .ant-tabs-tab{
          .ant-tabs-tab-btn{
            font-size: 16px;
          }
        }
      }
    }
  }
}
