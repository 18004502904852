@secondary-color: #ff00ae;
@primary-color: #f9df64;

.status-tag {
  font-size: 10px;
  color: white;
  padding: 1px 8px;
  background-color: @secondary-color;
  border-radius: 20px;

  &.upcoming,
  &.pending {
    background-color: #ffa64c;
  }

  &.done,
  &.paid {
    background-color: #48d581;
  }

  &.unverified,
  &.canceled {
    color: #ff290b;
    background-color: #fff2f0;
  }
}
