.service-card{
  background-color: transparent;
  position: relative;
  .service-card-image{
    margin-bottom: 15px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }
  .service-card-title{
    font-weight: bold;
    font-size: 20px;
  }
  .service-card-hover{
    position: absolute;
    content: "";
    top: 0;
    visibility: hidden;
    opacity: 0;
    left: 0;
    padding: 25px 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 100%;
    z-index: -1;
    background-color: white;
    box-shadow: 0 8px 20px rgba(2, 10, 49, 0.16);
    transition: all 0.25s;
    border-radius: 20px;
    .btn-secondary-shape{
      margin-top: auto;
      width: fit-content;
    }
  }

  &:hover{
    .service-card-hover{
      opacity: 1;
      visibility: visible;
      z-index: 10;
    }
  }
}
