@secondary-color: #ff00ae;

#auth-login-page {
  .auth-login-wrapper {
    transition: all 1s;
    .auth-login-title {
      font-family: "helsinki", sans-serif;
      font-size: 24px;
      text-align: center;
      margin-bottom: 24px;
    }
    .login-with-text {
      margin: 20px 0;
      font-size: 12px;
      text-align: center;
      &.large {
        font-size: 14px;
        margin: 20px 0 12px;
      }
    }

    .phone-password-phone {
      display: none;
      transition: all 1s;

      &.show {
        display: block;
      }
    }

    .forgot-password-action {
      color: #69707a;
    }

    .login-tab {
      .ant-tabs-nav {
        &::before {
          display: none;
        }

        .ant-tabs-nav-wrap {
          justify-content: center;
        }

        .ant-tabs-ink-bar {
          display: none;
        }

        .ant-tabs-tab {
          &.ant-tabs-tab-active {
            .ant-tabs-tab-btn {
              font-weight: bold;
              color: @secondary-color;
            }
          }

          &:hover {
            color: @secondary-color;
          }

          .ant-tabs-tab-btn {
            font-size: 18px;
          }
        }
      }
    }
  }
}
