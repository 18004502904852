@default-border-radius: 8px;
@screen-md: 768px;

.vui-alert{
  padding: 10px;
  border-radius: @default-border-radius;
  width: 100%;
  display: flex;
  align-items: center;
  background-color: #fff2f0;
  margin-bottom: 24px;
  .ant-typography{
    margin-bottom: 0;
    color: #FF290B;
    margin-left: 15px;
    margin-top: 1px;
  }

  svg{
    fill: #FF290B;
    width: 20px;
    height: 20px;
  }

  @media only screen and (max-width: @screen-md){
    .ant-typography{
      font-size: 14px;
    }
    svg{
      width: 28px;
      height: 28px;
    }
  }
}
