@default-border-radius: 8px;
@default-border-color: #edeeef;
@default-background-color: #f6f7f7;
@screen-md: 768px;
@success-color: #48d581;

.print-billing-invoice {
  .billing-invoice-header {
    padding: 0 60px;
    text-align: end;

    .logo-wrapper {
      max-width: 150px;
    }
    .billing-invoice-header-title {
      font-size: 12px;
      line-height: 15px;
    }
  }
  .billing-invoice-subheader {
    padding: 0 60px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;

    .billing-invoice-subheader-left {
      p {
        margin: 0;
        font-size: 12px;
      }
      .billing-invoice-subheader-title {
        font-weight: bold;
        font-size: 14px;
        margin: 0;
      }
    }

    .billing-invoice-subheader-right {
      .billing-invoice-status {
        margin-top: 15px;
        border: 4px solid @success-color;
        border-radius: @default-border-radius;
        max-width: 140px;
        margin-left: auto;
        text-align: center;
        padding: 0 15px;
        font-size: 24px;
        justify-content: center;
        text-transform: uppercase;
        color: darken(@success-color, 10%);
        font-weight: bold;
        display: flex;
        align-items: center;
        svg {
          margin-right: 10px;
          path {
            fill: @success-color;
          }
        }
      }
    }
  }
  .billing-invoice-body {
    padding: 0 60px 15px;
    .billing-invoice-body-title {
      font-size: 12px;
      margin-bottom: 5px;
    }
    .billing-invoice-item {
      display: block;
      page-break-before: auto;
      page-break-after: auto;
      page-break-inside: avoid;
      position: relative;
      padding-top: 20px;
    }
    .table-billing {
      width: 100%;
      font-size: 12px;
      border: 1px solid @default-border-color;
      border-radius: @default-border-radius;

      thead {
        background-color: @default-background-color;
      }
      tr {
        vertical-align: top;
        border-top: 1px solid @default-border-color;

        p {
          margin: 0;
        }
      }
      th {
        padding: 4px 12px;
      }
      td {
        padding: 6px 12px;
      }

      .text-align-left {
        text-align: left;
      }

      .text-no-wrap {
        white-space: nowrap;
      }

      .table-billing-description-wrapper {
        display: flex;
        .table-billing-description-left {
          flex: 0.35;
        }
        .table-billing-description-right {
          flex: 0.65;
          text-overflow: ellipsis;
        }
      }
      .table-billing-footer {
        display: flex;
        justify-content: space-between;
        padding: 10px;

        .table-billing-right {
          display: flex;

          .table-billing-inner-left {
            margin-right: 15px;
          }

          .table-billing-inner-right {
            text-align: right;
          }
        }

        .table-billing-title {
          font-weight: bold;
        }
      }
    }

    .schedule-section {
      margin-top: 10px;
      .schedule-title {
        font-weight: bold;
        margin-bottom: 2px;
      }

      .session-wrapper {
        margin-bottom: 4px;
        .session-title {
          font-size: 11px;
          font-weight: bold;
          margin-bottom: 2px;
        }
      }
    }
  }

  .billing-invoice-subbody {
    padding: 0 60px;
    font-size: 12px;
    page-break-inside: avoid;

    p {
      margin: 0;
      padding: 0;
      line-height: 20px;
    }

    .billing-bank-wrapper {
      display: flex;
      font-weight: bold;

      .billing-bank-wrapper-left {
        margin-right: 10px;
      }
    }

    .billing-email {
      font-weight: bold;
      color: darken(#fc3f93, 10%);
    }
  }

  .billing-invoice-footer-wave {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 60px;
    page-break-after: always;
    z-index: -1;
  }

  .billing-invoice-footer {
    padding: 60px 60px 60px;
    display: flex;
    width: 100%;
    page-break-before: auto;
    page-break-after: auto;
    page-break-inside: avoid;
    justify-content: flex-end;
    align-items: flex-end;
    margin-top: auto;
    font-size: 12px;

    p {
      margin: 0;
    }

    .billing-invoice-footer-right {
      margin-right: 5px;
      text-align: end;

      img {
        height: 50px;
        width: auto;
      }

      .signature-name {
        font-weight: bold;
        text-decoration: underline;
      }
    }
    .billing-invoice-footer-title {
      font-weight: bold;
    }
  }

  &.free {
    .billing-invoice-footer {
      position: relative;
    }
  }
}
