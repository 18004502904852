// Medium screen / desktop
@screen-md: 768px;

.banner-section {
  padding: 30px 0px;

  .banner-inner {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    align-items: center;

    .info-wrapper {
      grid-column-end: 6;
      grid-column-start: 1;
      grid-row-start: 1;
      z-index: 1;
    }

    .image-wrapper {
      grid-column-start: 6;
      grid-column-end: 13;
      grid-row-start: 1;
      position: relative;
      .bg{
        z-index: 0;
        position: relative;
      }
      .banner{
        position: absolute;
        z-index: 1;
        max-width: 72%;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
      }
    }

    @media only screen and (max-width: @screen-md) {
      display: flex;
      flex-direction: column;
      gap: 30px;
      text-align: center;
      padding: 0px 15px;
    }
  }
}

.description-section {
  padding: 20px 0;

  @media only screen and (max-width: @screen-md) {
    text-align: center;
    padding: 30px 15px;
  }
}

.vision-section {
  padding: 50px 0px;

  .vision-list {
    display: flex;
    flex-wrap: wrap;
    gap: 10%;
    max-width: 80%;
    margin: auto;

    .item {
      text-align: center;
      flex: 1 1 40%;
      margin-bottom: 30px;

      .icon-wrapper {
        margin-bottom: 25px;

        .icon {
          width: 160px;
          height: 160px;
          object-fit: contain;
        }
      }

      .title {
        font-size: 24px;
        font-family: "helsinki", sans-serif;
        line-height: 1;
      }
    }
  }

  @media only screen and (max-width: @screen-md) {
    padding: 30px 15px;

    .vision-list {
      flex-direction: column;
    }
  }
}

.main-title{
  text-align: left;
}

.hide-mobile{
  @media only screen and (max-width: @screen-md) {
    display: none;
  }
}

.show-mobile{
  display: none;
  font-weight: bold;
  text-align: left;
  @media only screen and (max-width: @screen-md) {
    display: revert;
  }
}

.btn-swiper{
  width: 30px;
  height: 30px;
  background: #ff00ae;
  border: none;
  color: white;
  font-size: 11px;
  border-radius: 50px;
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: absolute;
  right: 10px;
  top: 30%;
  z-index: 9;

  &.prev{
    left: 10px;
    right: revert;
  }

  display: none;

  @media only screen and (max-width: @screen-md) {
    display: revert;
  }
}

.swiper-button {
  &-prev,
  &-next {
    @media only screen and (max-width: @screen-md) {
      display: none;
    }
  }
}


.why-section {
  padding: 50px 0px;
  text-align: center;

  .why-list {
    list-style: none;
    padding: 0px;
    display: flex;
    flex-wrap: wrap;
    gap: 5%;
    width: 80%;
    margin: auto;
    margin-top: 50px;

    .item {
      flex: 1 1 20%;
      margin-bottom: 50px;

      .icon-wrapper {
        margin-bottom: 15px;

        .icon {
          width: 80px;
          height: 80px;
          object-fit: contain;
        }
      }

      .name {
        font-family: "helsinki", sans-serif;
      }
    }
  }

  @media only screen and (max-width: @screen-md) {
    padding: 30px 15px;

    .why-list {
      width: 100%;

      .item {
        flex: 1 1 100%;
      }
    }
  }
}

.client-section {
  padding: 50px 0px;
  text-align: center;

  .client-slider-wrapper {

    .client-slider {
      padding-bottom: 50px;
    }

    .swiper-pagination {
      position: absolute;
      bottom: 10px;
      &::before, &::after{
        display: none;
      }
    }

    .client-card {
      padding: 30px;
      background: #F9F9F9;
      text-align: left;

      .icon {
        width: 40px;
        margin-bottom: 15px;
      }

      .testi {
        min-height: 200px;
      }

      .info-wrapper {
        font-size: 13px;
        line-height: 1.5;

        .name {
          margin-bottom: 0px;
          font-weight: 700;
        }

        .desc {
          margin-bottom: 0px;
        }
      }
    }
  }

  @media only screen and (max-width: @screen-md) {
    padding: 30px 15px;

    .client-slider-wrapper{
      position: relative;
      .swiper-slide{
        padding-right: 50px;
        padding-left: 50px;
      }

      .swiper-pagination {
        &::before, &::after{
          display: revert;
        }
      }
    }
  }
}