#auth-register-phone-page {
  .auth-register-wrapper {
    .auth-register-title {
      font-family: "helsinki", sans-serif;
      font-size: 24px;
      text-align: center;
      margin-bottom: 24px;
    }
    .register-with-text {
      margin: 20px 0;
      font-size: 12px;
      text-align: center;
      &.large {
        font-size: 14px;
        margin: 20px 0 12px;
      }
    }
  }

  .auth-register-user-info-card {
    border-radius: 8px;
    background-color: #f4fcfc;
    margin-bottom: 20px;
    .ant-card-body {
      padding: 0 12px;
    }

    .ant-list-item {
      padding: 10px 0;
      .ant-list-item-meta {
        align-items: center;

        .ant-list-item-meta-title {
          margin-bottom: 0;
          font-weight: bold;
          font-size: 13px;
        }

        .ant-list-item-meta-description {
          font-size: 13px;
        }

        .ant-avatar {
          background: #67eae5;
          color: black;

          .ant-avatar-string {
            font-size: 12px;
            font-weight: bold;
          }
        }
      }
    }
  }
}
