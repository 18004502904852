@secondary-color: #ff00ae;

.vui-tabs {
  .ant-tabs-ink-bar {
    display: none;
  }
  &.ant-tabs-small {
    .ant-tabs-tab {
      .ant-tabs-tab-btn {
        font-size: 14px;
      }
    }
  }

  .ant-tabs-nav {
    &:before {
      border: 0;
    }
    .ant-tabs-tab {
      background-color: transparent !important;
      &:hover,
      &.ant-tabs-tab-active {
        .ant-tabs-tab-btn {
          color: @secondary-color !important;
        }
      }
      & + .ant-tabs-tab {
        margin: 0 0 0 20px;
      }
    }
  }
}
