.assessment-report {
  text-align: center;
  cursor: pointer;
  transition: all;
  min-width: 138px;
  max-width: 138px;
  .assessment-report-pdf-preview {
    position: relative;

    .pdf-icon {
      position: absolute;
      right: -13px;
      bottom: -9px;
    }
    margin-bottom: 8px;
  }

  .assessment-report-file-name {
    font-size: 10px;
  }

  .assessment-report-download {
    display: none;
    font-size: 10px;
    font-weight: bold;
  }

  &:hover {
    .assessment-report-file-name {
      display: none;
    }

    .assessment-report-download {
      display: unset;
    }
  }
}
