#auth-verify-phone-success-page {
  .auth-verify-phone-success-wrapper {
    text-align: center;
    .auth-verify-phone-success-title {
      font-family: "helsinki", sans-serif;
      font-size: 24px;
      text-align: center;
    }
  }
}
