@secondary-color: #ff00ae;
@screen-md: 768px;

#insight-detail {
  min-height: 100vh;

  .image-wrapper {
    margin-top: 50px;
    margin-bottom: 50px;
    border-radius: 15px;
    overflow: hidden;
  }

  .title {
    font-family: "helsinki", sans-serif;
    font-size: 26px;
    font-weight: 700;
    margin-bottom: 0px;
  }

  .icon-vertical-list {
    list-style: none;
    padding: 0px;
    margin: 0px;
    display: flex;
    flex-wrap: wrap-reverse;
    align-items: center;
    gap: 15px;
    font-weight: 700;
    margin-bottom: 30px;

    .item {
      display: flex;
      gap: 5px;

      .icon {
        transform: translateY(9px);
        path {
          fill: #fc3f93;
        }
      }

      .text {
        flex: 1;
      }
    }
  }

  .content-separation {
    display: grid;
    grid-template-columns: 8fr 2fr;
    gap: 50px;
  }

  .related-list {
    display: grid;
    grid-gap: 15px;

    .section-title {
      margin-bottom: 15px;
    }

    .item {
      .img-wrapper {
        border-radius: 15px;
        overflow: hidden;
        margin-bottom: 10px;
        max-height: 150px;

        .img {
          height: 100%;
          object-fit: cover;
          object-position: center center;
        }
      }

      .item-title {
        color: black;
        font-weight: 700;
      }
    }
  }

  .description {
    margin-bottom: 50px;

    img {
      margin: 50px 0px;
    }
  }

  .section-title {
    font-family: "helsinki", sans-serif;
    font-weight: 700;
  }

  .author-wrapper {
    margin-bottom: 30px;

    .author-content {
      display: grid;
      grid-template-columns: 3fr 10fr;
      gap: 30px;
      align-items: center;

      .info-wrapper {
        .name {
          color: #fc3f93;
          font-weight: 700;
        }

        .desc {
          margin-bottom: 0px;
        }
      }
    }
  }

  .tag-wrapper {
    margin-bottom: 30px;

    .ant-tag {
      color: black;
    }
  }

  .share-wrapper {
    .socmed-list {
      list-style: none;
      margin: 0px;
      padding: 0px;
      display: flex;
      align-items: center;
      gap: 15px;

      .item {
        display: flex;
        align-items: center;
        justify-content: center;
        background: @secondary-color;
        width: 35px;
        height: 35px;
        border-radius: 100%;
        cursor: pointer;

        .icon {
          path {
            fill: white;
          }
        }
      }
    }
  }

  @media only screen and (max-width: @screen-md) {
    .content-separation {
      grid-template-columns: 1fr;
    }

    .author-wrapper .author-content {
      grid-template-columns: 1fr;
    }
  }
}
