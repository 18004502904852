@default-border-radius: 8px;
@screen-md: 768px;

.patient-information-card{
  border: 1px solid #e0e2e3;
  border-radius: @default-border-radius;
  padding: 20px;
  margin-bottom: 16px;
  .vui-form-text-wrapper{
    margin-bottom: 0;
  }

  .patient-information-card-wrapper{
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 24px;
    .patient-information-card-left{
      flex: 0.45;
    }
    .patient-information-card-right{
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 16px;
      flex: 0.55;
    }
  }

  @media only screen and (max-width: @screen-md){
    padding: 15px;
    .patient-information-card-wrapper{
      flex-direction: column;
      align-items: unset;
      .patient-information-card-right{
        flex-direction: column;
        align-items: unset;
      }
    }
  }
}
