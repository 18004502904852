@main-color: #ff00ae;
@disabled-color: rgba(198, 200, 204, 1);

.btn-secondary-shape {
  display: inline-flex;
  align-items: center;
  font-size: 18px;
  color: @main-color;
  position: relative;
  padding-right: 20px;
  height: 40px;
  padding-left: 55px;
  border: none;
  background: transparent;
  outline: none;
  cursor: pointer;

  &:focus,
  &:active {
    border: none;
    background: transparent;
    outline: none;
  }

  &:before {
    content: "";
    width: 40px;
    height: 40px;
    padding: 10px;
    background: @main-color;
    border-radius: 50px;
    position: absolute;
    left: 0;
    transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  }

  .shape {
    position: absolute;
    left: 0;
    min-width: 36px;
    height: 36px;
    padding: 10px;
    background: @main-color;
    border-radius: 100%;
    z-index: 2;
    margin-top: 2px;
    margin-left: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 15px;
    transition: all 0.5s ease;

    &:before {
      content: "";
      height: 1.5px;
      width: 0;
      background: white;
      margin-right: 0;
      visibility: hidden;
      opacity: 0;
      position: relative;
      left: 0;
      transition: all 0.5s ease;
    }

    &:after {
      content: url("data:image/svg+xml, %3Csvg width='14' height='14' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M4.62115 0.746094C4.54652 0.746112 4.47359 0.768396 4.4117 0.810094C4.3498 0.851792 4.30176 0.911009 4.27371 0.980167C4.24566 1.04932 4.23887 1.12528 4.25423 1.19831C4.26959 1.27135 4.30639 1.33814 4.35992 1.39014L8.96978 6L4.35992 10.6099C4.32393 10.6444 4.2952 10.6858 4.27541 10.7316C4.25561 10.7774 4.24516 10.8267 4.24465 10.8766C4.24414 10.9265 4.25359 10.976 4.27245 11.0221C4.29131 11.0683 4.3192 11.1103 4.35447 11.1456C4.38975 11.1809 4.43172 11.2087 4.47791 11.2276C4.5241 11.2465 4.57359 11.2559 4.62348 11.2554C4.67337 11.2549 4.72266 11.2444 4.76845 11.2247C4.81425 11.2049 4.85564 11.1761 4.89019 11.1401L9.76519 6.26514C9.83549 6.19481 9.87499 6.09944 9.87499 6C9.87499 5.90056 9.83549 5.80519 9.76519 5.73486L4.89019 0.859863C4.85524 0.823867 4.81342 0.79525 4.76721 0.775708C4.72099 0.756166 4.67133 0.746096 4.62115 0.746094Z' fill='white' stroke='white' /%3E%3C/svg%3E");
    }
  }

  .text {
    position: relative;
    z-index: 2;
    transition: all 0.5s ease;
    font-size: 16px;
  }

  &:hover {
    .shape {
      left: 10px;
    }

    .text {
      color: white;
    }

    &:before {
      width: 100%;
    }
  }

  &.left-type {
    padding-right: 55px;
    padding-left: 20px;

    &:before {
      right: 0;
      left: unset;
    }

    &:hover {
      .shape {
        &:before {
          margin-left: -8px;
          margin-right: 0;
        }
      }
    }

    .shape {
      right: 0;
      left: unset;
      margin-right: 0;
      margin-left: 15px;
      padding-left: 5.5px;
      margin-top: 0;

      &:before {
        left: unset;
        right: 0;
      }

      &:after {
        margin-right: 1px;
        content: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.28998 11.2554C8.36461 11.2554 8.43754 11.2331 8.49943 11.1914C8.56133 11.1497 8.60938 11.0905 8.63743 11.0213C8.66548 10.9521 8.67226 10.8762 8.6569 10.8032C8.64154 10.7301 8.60474 10.6633 8.55121 10.6113L3.94135 6.00147L8.55121 1.3916C8.5872 1.35705 8.61593 1.31566 8.63573 1.26986C8.65552 1.22406 8.66598 1.17478 8.66648 1.12489C8.66699 1.075 8.65754 1.02551 8.63868 0.979317C8.61982 0.933126 8.59194 0.891162 8.55666 0.855883C8.52138 0.820604 8.47941 0.792719 8.43322 0.77386C8.38703 0.755002 8.33754 0.74555 8.28765 0.746057C8.23776 0.746564 8.18848 0.75702 8.14268 0.776814C8.09688 0.796607 8.05549 0.82534 8.02094 0.861329L3.14594 5.73633C3.07564 5.80666 3.03615 5.90203 3.03615 6.00147C3.03615 6.10091 3.07564 6.19627 3.14594 6.2666L8.02094 11.1416C8.05589 11.1776 8.09771 11.2062 8.14393 11.2258C8.19014 11.2453 8.23981 11.2554 8.28998 11.2554V11.2554Z' fill='white' stroke='white'/%3E%3C/svg%3E%0A");
      }
    }
  }

  &.disabled {
    color: @disabled-color;

    .shape {
      background: @disabled-color;
    }

    &:before {
      display: none;
    }

    &:hover {
      .shape {
        left: 0;
      }

      .text {
        color: @disabled-color;
      }
    }
  }
}
