@screen-md: 768px;
@primary-color: #FEDF47;
@secondary-color: #ff00ae;

#contact {
  min-height: 100vh;

  .info-list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 16px;

    .item {
      display: flex;
      background-color: #f4fcfc;
      border-radius: 10px;
      flex-direction: column;
      align-items: center;
      padding: 26px 20px;
      gap: 15px;
      text-align: center;
      width: 100%;
      margin: auto;
      height: 100%;

      .icon {
        height: 45px;
        width: auto;
      }

      .title {
        font-family: "helsinki", sans-serif;
        font-weight: 700;
        font-size: 24px;
        margin: 0px;
      }

      .desc {
        margin: 0px;
      }
      .mail {
        color: @secondary-color !important;
        font-weight: 700;
        transition: all 300ms;
        &:hover {
          font-size: 16px;
        }
      }
    }
  }

  .form-section {
    margin-top: 100px;
    display: flex;
    .maps-wrapper {
      flex: 1;
      iframe {
        border: none;
        border-radius: 10px 0 0 10px;
        width: 100%;
        height: 100%;
      }
    }

    .form-wrapper {
      flex: 1;
      background: #f4fcfc;
      padding: 30px;
      border-radius: 0 10px 10px 0;

      .title {
        font-family: "helsinki", sans-serif;
        font-weight: 700;
        font-size: 24px;
      }

      .form-submit-wrapper {
        text-align: right;
      }
    }
  }

  @media only screen and (max-width: @screen-md) {
    .info-list {
      grid-template-columns: 1fr;
      gap: 30px;
    }

    .form-section {
      display: flex;
      flex-direction: column;
      .maps-wrapper {
        iframe {
          width: 100%;
          height: 300px;
        }
      }
    }
  }
}
