@secondary-color: #ff00ae;
@screen-md: 768px;

#event-section {
  min-height: 100vh;

  .header-event {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 30px;
    margin-bottom: 30px;

    .vui-title-wrapper {
      margin-bottom: 0px;
    }

    .tab-list {
      list-style: none;
      padding: 0px;
      margin: 0px;
      display: flex;
      gap: 50px;

      .item {
        cursor: pointer;
        font-size: 20px;
        transition: all 0.5s ease;

        &.active {
          color: @secondary-color;
          font-weight: 700;
        }
      }
    }
  }

  .filter-section {
    display: flex;
    justify-content: space-between;
    margin-bottom: 80px;
    .search-wrapper {
      width: 100%;
      max-width: 380px;
    }

    .year-filter-wrapper {
      .ant-picker {
        width: 250px;
        background-color: #eafffe;
        border: none;
        cursor: pointer;
        .ant-picker-input {
          input {
            cursor: pointer;
          }
        }
        ::placeholder {
          color: black;
          font-weight: 700;
        }
      }
    }
  }

  .pagination-wrapper {
    text-align: center;
    margin-top: 50px;
  }

  @media only screen and (max-width: @screen-md) {
    .header-event {
      flex-direction: column;
      gap: 15px;
      align-items: flex-start;
    }

    .filter-section {
      display: block;
      margin-bottom: 80px;
      .search-wrapper {
        width: 100%;
        max-width: 250px;
        margin-bottom: 30px;
      }
    }
  }
}
