@secondary-color: #ff00ae;
@third-color: #fc3f93;
@fourth-color: #000000;
@five-color: #f3d230;
@screen-md: 768px;
@primary-color: #fedf47;
@default-border-radius: 8px;

.psychologist-card {
  min-height: 475px;
  background-color: transparent;
  position: relative;
  text-align: center;

  .psychologist-card-image {
    margin: 0 auto 15px;
    width: 100%;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }
  .psychologist-card-name {
    font-weight: bold;
    font-size: 20px;
    margin: 12px 0 0;
  }
  .psychologist-card-title {
    font-size: 16px;

    p,
    span,
    strong,
    i,
    div,
    a,
    small {
      margin: 0;
    }
  }
  .psychologist-card-rate {
    color: @fourth-color;
    font-weight: 700;
    font-size: 15px;

    .highlight-yellow {
      background-color: @primary-color;
      border-radius: @default-border-radius;
      padding: 0.5em 1em;
    }
  }
  .psychologist-card-hover {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    padding: 25px 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    min-height: 100%;
    z-index: -1;
    visibility: hidden;
    opacity: 0;
    background-color: white;
    box-shadow: 0 8px 20px rgba(2, 10, 49, 0.16);
    border-radius: 20px;
    transition: all 0.25s ease;
    .psychologist-card-image {
      width: 65%;
    }
    .psychologist-card-name {
      color: @secondary-color;
      font-size: 18px;
      margin-bottom: 10px;
    }
    .psychologist-card-summary {
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      display: -webkit-box;
    }
    .btn-secondary-shape {
      margin: auto auto 0;
      width: fit-content;
    }

    &.choice {
      padding: 10px 39px;

      .psychologist-card-name {
        font-size: 15px;
        margin-bottom: 0;
      }

      .psychologist-card-title {
        margin-bottom: 10px;
      }

      .psychologist-card-summary {
        font-size: 13px;
        color: #888e95;
        -webkit-line-clamp: 4;
      }
    }
  }

  &:hover {
    .psychologist-card-image {
      &.first-image {
        border-radius: 20px;
      }
    }
    .psychologist-card-hover {
      opacity: 1;
      visibility: visible;
      z-index: 10;
    }
  }

  &.psychologist-card-choice {
    position: relative;
    border: 1px solid #e0e2e3;
    min-height: 185px;
    text-align: left;
    border-radius: 20px;

    .psychologist-card-content-wrapper {
      display: flex;
      align-items: center;
    }

    .psychologist-card-radio {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 12;
      width: 100%;
      height: 100%;

      .ant-radio-wrapper {
        width: 100%;
        height: 100%;
        padding-top: 10px;
        padding-left: 10px;
        text-align: left;
      }

      .ant-radio.ant-radio-checked:after {
        background: transparent !important;
      }
    }

    .psychologist-card-image {
      width: 35% !important;
      border-top-left-radius: 10px !important;
      border-top-right-radius: 10px !important;
      margin: 0;
    }

    .psychologist-card-name {
      font-size: 16px;
      font-weight: 700;
      color: @fourth-color;
      margin-bottom: 2px;
    }

    .psychologist-card-title {
      color: @third-color;
      font-weight: 400;
      font-size: 14px;
    }

    .psychologist-card-nearest-schedule {
      font-size: 11px;
      color: #888e95;
      margin: 2px 0 13px 0;
    }

    .psychologist-card-rate {
      font-weight: 400;
      margin-bottom: 25px;
    }
  }

  @media only screen and (max-width: @screen-md) {
    &:hover {
      .psychologist-card-hover {
        &.choice {
          opacity: 0;
          visibility: hidden;
        }
      }
    }
  }
}
