@primary-color: #FEDF47;
@secondary-color: #ff00ae;
@screen-md: 768px;

#vui-footer{
  position: relative;
  background-color: @primary-color;
  padding: 80px 0;
  margin-top: 80px;

  .footer-title{
    font-family: "helsinki", sans-serif;
    font-size: 24px;
  }

  .footer-social-media-space{
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    .footer-social-media-link {
      &:hover {
        svg {
          path {
            fill: @secondary-color
          }
        }
      }
    }
    .ant-space-item{
      display: flex;
    }
    .copyright{
      margin-top: 32px;
      margin-bottom: 0;
    }
  }

  .vui-footer-wave-bg{
    position: absolute;
    height: 80px;
    top: -78px;
    left: 0;
    width: 100%;
    img{
      width: 100%;
    }
  }

  .vui-footer-wrapper{
    .logo-wrapper{
      width: 280px;
    }
    .footer-description{
      margin: 28px 0 58px;
    }
    .footer-info-wrapper{
      display: flex;
      justify-content: space-between;
      .footer-link-body{
        .footer-link-wrapper{
          display: flex;
          flex-direction: column;
          gap: 12px;
          .footer-link{
            color: #232d3b;
            &:hover {
              color: @secondary-color;
              font-weight: bold;
            }
          }
        }
      }
    }
    .footer-contact-email{
      .input-border-bottom{
        border: none;
        border-bottom: 2px solid black;
        background: transparent;
        padding: 8px 0;
        input{
          background: transparent;
          &::placeholder{
            color: #232d3b;
          }
        }
        .ant-input-suffix{
          color: @secondary-color;
          cursor: pointer;
          &:hover {
            font-weight: bold;
          }
        }
      }
    }
  }

  @media only screen and (max-width: @screen-md){
    padding: 40px 15px;
    margin-top: 40px;
    .container{
      overflow-x: hidden;
    }
    .vui-footer-wave-bg{
      height: 40px;
      top: -38px;
    }
    .footer-title{
      margin-bottom: 10px;
      font-size: 28px;
    }
    .vui-footer-wrapper{
      .footer-description{
        margin: 28px 0 40px;
      }
      .footer-info-wrapper{
        flex-direction: column;
        gap: 36px;
        margin-bottom: 36px;
        .footer-link-body{
          .footer-link-wrapper{
            .footer-link{
              font-size: 18px;
            }
          }
        }
      }
    }
    .footer-social-media-space{
      margin-top: 46px;
      .copyright{
        margin-top: 20px;
      }
    }
  }
}
