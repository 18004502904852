@text-color: #232d3b;
@active-color: #ff00ae;
@screen-lg: 992px;
@screen-md: 768px;
@screen-xs: 425px;

.vui-notification-wrapper{
  position: absolute;
  z-index: -1;
  visibility: hidden;
  top: 245px;
  opacity: 0;
  background-color: white;
  border-radius: 8px;
  right: 0;
  min-width: 380px;
  max-width: 380px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  transition: all 0.5s;
  .vui-notification-header{
    padding: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .vui-notification-header-title{
      font-size: 16px;
      font-family: "helsinki", sans-serif;
    }
    .vui-notification-header-action{
      font-size: 12px;
      cursor: pointer;
    }
  }
  .vui-notification-list{
    max-height: 380px;
    overflow-y: scroll;
    .vui-notification-item{
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 40px;
      padding: 15px;
      border-bottom: 1px solid #D9D9D9;
      white-space: normal;
      .vui-notification-item-info{
        .vui-notification-item-info-message{
          font-size: 12px;
        }
        .vui-notification-item-info-time{
          font-size: 11px;
          color: #0361CC;
        }
      }

      .vui-notification-item-status {
        position: relative;
        .status {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 25px;
          height: 25px;
          border-radius: 100%;
          background: transparent;
          transition: all 0.5s ease;

          &:after {
            display: none;
            content: '';
            position: absolute;
            width: 7px;
            height: 7px;
            border-radius: 100%;
            background: #FF290B;
          }
        }
      }

      &:last-child{
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
        border-bottom: none;
      }

      &.unread{
        background-color: rgba(119, 218, 213, 0.2);
        .vui-notification-item-status{
          .status:after{
            display: block;
          }
        }
        .vui-notification-item-info{
          .vui-notification-item-info-message{
            line-height: 1.5;
          }
          .vui-notification-item-info-message, .vui-notification-item-info-time{
            font-weight: bold;
          }
        }
      }
    }
  }

  @media only screen and (max-width: @screen-md){
    min-width: 100vw;
  }

  &.open{
    right: -6vw;
    top: 45px;
    opacity: 1;
    z-index: 99;
    min-width: 380px;
    visibility: visible;
    @media only screen and (max-width: @screen-md){
      top: 58px;
      left: 0;
      right: 0;
      box-shadow: none;
      border-radius: 0;
      min-width: 100vw;
      .vui-notification-header{
        margin-top: 10px;
      }
    }
  }
}
