@default-border-radius: 8px;
@screen-md: 768px;
@screen-xs: 321px;

.history-card {
  border: 1px solid #e0e2e3;
  border-radius: @default-border-radius;
  padding: 20px;
  margin-bottom: 16px;
  .vui-form-text-wrapper {
    margin-bottom: 0;
  }

  .history-card-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 10px;
    text-align: right;

    .ant-typography {
      margin-bottom: 0;
    }

    .history-tag-wrapper {
      display: flex;
      gap: 6px;
    }

    .booking-code-title {
      font-size: 10px;
    }

    .booking-code {
      font-size: 13px;
      color: #c6c8cc;
    }
  }

  .history-card-wrapper {
    display: flex;
    justify-content: space-between;
    gap: 24px;

    .history-card-data {
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      grid-gap: 20px;
      flex: 0.85;
    }

    .history-action-wrapper {
      display: flex;
      flex-direction: column;
      gap: 22px;
      justify-content: center;

      .history-attachment-wrapper {
        display: flex;
        gap: 18px;
        .history-attachment-btn {
          text-align: center;
        }
      }
    }
  }

  @media only screen and (max-width: @screen-md) {
    padding: 15px;

    .history-card-header {
      .history-tag-wrapper {
        align-items: center;
      }

      .booking-tag-title,
      .booking-code {
        font-size: 9px;
      }

      .counseling-tag,
      .status-tag,
      .booking-code-title {
        font-size: 7px;
      }
    }

    .history-card-wrapper {
      flex-direction: column;
      align-items: unset;

      .history-card-data {
        grid-template-columns: repeat(1, 1fr);
        border-bottom: 1px solid #edeeef;
        padding-bottom: 16px;
      }

      .history-action-wrapper {
        flex-direction: column-reverse;
        align-items: center;

        .ant-spin-nested-loading {
          width: 100%;
        }
      }
    }
  }

  @media only screen and (max-width: @screen-xs) {
    padding: 15px;

    .history-card-header {
      .booking-tag-title,
      .booking-code {
        font-size: 7px;
      }

      .counseling-tag,
      .status-tag,
      .booking-code-title {
        font-size: 5px;
      }
    }
  }
}
