@text-color: #232d3b;
@active-color: #ff00ae;
@primary-color: #fedf47;
@screen-xl: 1199px;
@screen-lg: 992px;
@screen-md: 768px;

.vui-navbar-dropdown-account-menu {
  padding: 10px 22px;
  border-radius: 8px;
  margin-top: 10px;
  min-width: 200px;
  .ant-dropdown-menu-item {
    padding: 8px 12px 8px 0;
    transition: all 0.2s;
    &:not(:last-child) {
      border-bottom: 0.5px solid #edeeef;
    }
    &:hover {
      background-color: initial;
      color: @active-color;
    }
  }
}

#vui-navbar {
  font-size: 16px;
  position: sticky;
  top: 0;
  background: white;
  z-index: 100;
  ::-webkit-scrollbar {
    width: 6px;
  }

  ::-webkit-scrollbar-thumb {
    background: #d9d9d9;
    border-radius: 10px;
  }
  .ant-badge {
    .ant-badge-count {
      background: @active-color;
      &.ant-badge-count-sm {
        font-size: 10px;
      }
      &.ant-badge-multiple-words {
        padding: 0 4px;
      }
    }
  }

  .vui-navbar-icon-badge {
    .ant-badge-count {
      top: 3px;
      right: 2px;
    }
  }

  .vui-navbar-menu-link {
    color: @text-color;
    &:hover {
      color: @active-color;
    }
    &:not(.has-square).active {
      color: @active-color;
      font-weight: 600;
    }
    &.has-square {
      background-color: @primary-color;
      padding: 0 12px;
      color: black;
      font-family: "Helsinki", sans-serif;
      border-radius: 8px;
    }
  }
  .vui-navbar-space {
    height: 74px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .vui-navbar-menu-space {
      display: flex;
      align-items: center;
      gap: 24px;
      white-space: nowrap;
      z-index: 999;

      svg {
        cursor: pointer;
      }

      .ant-badge {
        &:hover {
          svg {
            path {
              fill: @active-color;
            }
          }
        }
      }
    }

    .vui-navbar-dropdown-account {
      cursor: pointer;

      &:hover {
        svg {
          path {
            fill: @active-color;
          }
        }
        .vui-navbar-menu-link {
          color: @active-color;
        }
      }

      .ant-space-item {
        display: flex;
      }
    }

    .vui-navbar-hamburger-icon {
      display: none;
    }

    .vui-dropdown-account-action {
      display: flex;
      align-items: center;
      gap: 8px;
    }

    .logo-wrapper {
      z-index: 999;
    }

    @media only screen and (max-width: @screen-xl) {
      .logo-wrapper {
        max-width: 160px;
      }
    }

    @media only screen and (max-width: @screen-lg) {
      padding: 0 10px;
      .vui-navbar-menu-space {
        gap: 16px;

        .vui-navbar-menu-link {
          font-size: 12px;
        }

        svg {
          width: 24px;
        }
      }
    }

    @media only screen and (max-width: @screen-md) {
      padding: 5px 10px 12px 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      gap: 24px;

      .vui-navbar-menu-space.desktop {
        display: none;
      }

      .vui-navbar-hamburger-icon {
        display: block;
      }

      .logo-wrapper {
        max-width: 200px;
      }
    }

    @media only screen and (max-width: @screen-md) {
      height: 58px;
    }
  }

  .vui-navbar-menu-space-notification {
    display: flex;
    .ant-typography {
      margin-bottom: 0;
    }
    @media only screen and (min-width: @screen-md) {
      position: relative;
    }
  }

  // navbar mobile
  .vui-navbar-mobile {
    position: absolute;
    top: 74px;
    background-color: white;
    width: 0;
    height: 100%;
    padding: 40px 15px;
    text-align: left;
    transition: all 0.5s;
    opacity: 0;
    left: -400px;
    z-index: -99;
    .vui-navbar-mobile-menu-wrapper {
      display: flex;
      flex-direction: column;
      gap: 24px;
      .vui-navbar-menu-link {
        font-weight: 500;
        font-size: 18px;
      }
    }

    &.open {
      left: 0;
      opacity: 1;
      z-index: 99;
      position: fixed;
      width: 100%;
    }

    @media only screen and (max-width: @screen-md) {
      top: 56px;
    }
  }
}
