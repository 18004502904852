@secondary-color: #ff00ae;
@screen-xs: 480px;

.vui-calendar{
  .events {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  .events .ant-badge-status {
    width: 100%;
    overflow: hidden;
    font-size: 12px;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .notes-month {
    font-size: 28px;
    text-align: center;
  }
  .notes-month section {
    font-size: 28px;
  }

  .ant-badge-status-dot{
    color: @secondary-color;
  }

  .ant-picker-calendar-header{
    justify-content: center;
    margin-bottom: 5px;
  }

  &.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-today{
    background-color: transparent !important;
    border-color: @secondary-color;
  }

  &.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected .ant-picker-calendar-date{
    background-color: #fce1f4;
    .ant-picker-calendar-date-value{
      color: @secondary-color;
    }
  }

  .ant-radio-button-wrapper {
    height: 40px;
    line-height: 38px;
    padding: 0 16px;
    &:first-child{
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
    }
    &:last-child{
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
    }
    &.ant-radio-button-wrapper-checked {
      border-color: @secondary-color !important;
      color: @secondary-color;
      &:before{
        background-color: @secondary-color;
      }
    }
    &:hover{
      color: @secondary-color;
    }
  }

  @media only screen and (max-width: @screen-xs){
    .ant-picker-calendar-header{
      display: flex;
    }
  }
}
