@screen-md: 768px;

.card-our-client {
  position: relative;

  .client-box {
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 200px;
    background: #F9F9F9;
    cursor: pointer;
    transition: all .4s ease;

    &:hover {
      box-shadow: 0 0 15px 0px #c2c2c2;
      transition: 0.7s;

      .logo-wrapper {
        top: 0px;
        transform: translateY(0);
      }

      .logo {
        max-width: 100px;
        filter: grayscale(1);
        margin-bottom: 5px;
      }

      .text-wrapper {
        position: relative;
        visibility: visible;
        opacity: 1;
        margin-top: 12px;
        display: block;
      }
    }

    .logo-wrapper {
      position: relative;
      width: 100%;
      transition: all .4s ease;
    }

    .logo {
      width: 100%;
      max-width: 160px;
      filter: grayscale(0);
      transition: all .4s ease;
      margin-bottom: 0 !important;
      object-fit: contain;
      object-position: center;
    }

    .text-wrapper {
      display: none;
      visibility: hidden;
      opacity: 0;
      padding: 0px 10px;
      transition: opacity .4s ease-in;
    }

    .title {
      margin-bottom: 0px;
      font-weight: 700;
      font-size: 14px;
      line-height: 1.5;
    }

    .type {
      font-size: 13px;
      margin-bottom: 0px;
    }

    .client-info {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      text-align: center;
      width: 100%;
      height: 100%;
    }
  }
  @media only screen and (max-width: @screen-md){
    padding: 0 40px;
    
    .client-box{
      max-width: 300px;
      margin: auto;
      height: 210px;
    }
  }
}
