#auth-register-page {
  .auth-register-wrapper {
    .auth-register-title {
      font-family: "helsinki", sans-serif;
      font-size: 24px;
      text-align: center;
      margin-bottom: 24px;
    }
    .register-with-text {
      margin: 20px 0;
      font-size: 12px;
      text-align: center;
      &.large {
        font-size: 14px;
        margin: 20px 0 12px;
      }
    }
  }

  .ant-form-item-required {
    &::after {
      display: inline-block !important;
      content: "*" !important;
      color: red;
      font-weight: 400;
    }
  }
}
