@default-border-radius: 8px;
@default-border-color: #edeeef;
@screen-md: 768px;

#counseling-form-page{
  padding: 40px 0;
  background-color: #F6F7F7;
  min-height: 100vh;
  .ant-radio-group{
    width: 100%;
    .ant-space{
      width: 100%;
      .ant-radio-wrapper{
        width: 100%;
        span:not(.ant-radio-inner, .ant-radio){
          width: 100%;
        }
      }
    }
  }

  .table-form-wrapper{
    width: 100%;
    thead{
      background-color: @default-border-color;
    }
    th:first-child{
      border-radius: @default-border-radius 0 0 @default-border-radius;
    }
    th:last-child{
      border-radius:0 @default-border-radius @default-border-radius 0;
    }
    th{
      padding: 8px;
    }
    tbody{
      td:not(:last-child){
        padding: 8px;
      }
      tr:first-child{
        td{
          padding-top: 16px;
          padding-bottom: 8px;
        }
      }
    }
  }

  .form-page-wrapper{
    padding: 0 120px;
    text-align: center;
    .logo-wrapper{
      width: 196px;
      margin: 0 auto 28px;
      text-align: center;
    }
    .page-title{
      font-weight: 800;
      text-align: center;
    }
    .page-subtitle{
      margin-bottom: 52px;
      display: block;
      p {
        margin-bottom: 0px;
      }
    }

    .form-card-wrapper{
      text-align: left;
      .counseling-form-card{
        background-color: white;
        padding: 24px;
        border-radius: @default-border-radius;
        margin-bottom: 20px;
        .counseling-form-card-wrapper{
          .ant-form-item{
            margin-bottom: 0;
          }
        }
      }
      .ant-spin-nested-loading{
        width: fit-content;
      }
    }
  }

  @media only screen and (max-width: @screen-md){
    .form-page-wrapper{
      padding: 0 15px;
    }
  }
}
