@secondary-color: #ff00ae;
@screen-md: 768px;

#vui-merchandise-card{
  width: 100%;
  background-color: transparent;
  .merchandise-card-image{
    width: 100%;
  }
  .merchandise-card-title{
    font-weight: bold;
    margin: 16px 0 0;
    letter-spacing: 1px;
  }
  .merchandise-card-description{
    margin: 0;
  }
  .merchandise-card-link{
    color: @secondary-color;
    font-weight: bold;
    font-size: 14px;
    cursor: pointer;
  }

  @media only screen and (max-width: @screen-md){
    max-width: 300px;
    margin: auto;
    padding: 0 40px;

    .merchandise-card-title{
      font-size: 16px;
    }

    .merchandise-card-description{
      font-size: 12px;
    }
  }
}
