// Medium screen / desktop
@screen-md: 768px;

.vui-subtitle {
  font-size: 16px;
  line-height: 2.5;
  color: #232D3B;
  display: block;
  @media only screen and (max-width: @screen-md) {
    font-size: 14px;
    line-height: 2;
  }
}
