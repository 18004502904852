// Medium screen / desktop
@screen-md: 768px;

.vui-title-wrapper {
  margin-bottom: 25px;
}

.vui-title {
  font-family: "helsinki", sans-serif;
  display: inline;
  margin-bottom: 15px;
  color: #232D3B;
  position: relative;

  &.has-shadow{
    text-shadow: 0px 4px 5px #bcbcbc;
  }

  &:before {
    content: "";
    position: absolute;
    left: 0;
    bottom: -8px;
    width: 100%;
    height: 32px;
    background: #67EAE5;
    opacity: 0.5;
    z-index: -1;

    @media only screen and (max-width: @screen-md) {
      height: 25px;
      left: 0;
      transform: translateX(0%);
    }
  }
}

h1 {
  &.vui-title {
    font-size: 48px;
    line-height: 1.4;

    @media only screen and (max-width: @screen-md) {
      font-size: 32px;
    }
  }
}
