@screen-md: 768px;

#insight-section {
  min-height: 100vh;

  .featured-section {
    display: grid;
    grid-template-columns: 8fr 4fr;
    gap: 30px;
    margin-bottom: 30px;

    .big-card {
      cursor: pointer;
      color: black;

      &:hover {
        .image-wrapper {
          .image {
            transform: scale(1.1);
          }
        }
      }

      .image-wrapper {
        max-height: 500px;
        border-radius: 15px;
        overflow: hidden;

        .image {
          height: 100%;
          object-fit: cover;
          object-position: center center;
          transform: scale(1);
          transition: all 0.5s ease;
        }
      }

      .info-wrapper {
        background: #f6f7f7;
        padding: 30px;
        border-radius: 15px;
        width: 85%;
        margin: auto;
        margin-top: -15%;
        position: relative;

        .category {
          color: #fc3f93;
          font-weight: 700;
        }

        .title {
          font-size: 24px;
          font-weight: 700;
          line-height: 1.5;
        }

        .summary {
          line-height: 2.5;
          margin-bottom: 5px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 4;
          -webkit-box-orient: vertical;
        }

        .icon-vertical-list {
          list-style: none;
          padding: 0px;
          margin: 0px;
          display: flex;
          flex-wrap: wrap-reverse;
          align-items: center;
          gap: 15px;
          font-size: 12px;
          font-weight: 700;

          .item {
            display: flex;
            gap: 5px;

            .icon {
              transform: translateY(5px);
              path {
                fill: #fc3f93;
              }
            }

            .text {
              flex: 1;
            }
          }
        }
      }
    }

    .side-featured {
      display: grid;
      grid-gap: 30px;
    }
  }

  .more-section {
    display: grid;
    grid-template-columns: 8fr 4fr;
    gap: 30px;

    .more-wrapper {
      display: grid;
      grid-gap: 50px;
    }

    .more-list {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 15px;
      margin-bottom: 30px;
    }

    .section-title {
      font-size: 24px;
      font-weight: 700;
      margin-bottom: 30px;
    }

    .more-btn-wrapper {
      text-align: center;
    }

    .category-wrapper {
      .spin-category-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 200px;
      }

      .category-list {
        list-style: none;
        padding: 0px;
        margin: 0 0 1.5em 0;
        display: grid;
        grid-gap: 15px;
      }

      .category-pagination-wrapper {
        text-align: center;
      }
    }

    .card-category {
      background: rgba(#fedf47, 0.2);
      display: flex;
      align-items: center;
      padding: 15px 30px;
      border-radius: 15px;
      color: #fc3f93;
      font-weight: 700;
      gap: 15px;
      transition: all;

      &:hover {
        transform: scale(101%);
      }

      .icon-wrapper {
        width: 40px;
        height: 40px;
        background: #fc3f93;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        .icon {
          width: 30px;
        }
      }
      .name {
        cursor: pointer;
      }
    }
  }

  @media only screen and (max-width: @screen-md) {
    .featured-section {
      grid-template-columns: 1fr;

      .big-card {
        .info-wrapper {
          padding: 15px;
          width: 90%;
        }
      }
    }

    .more-section {
      grid-template-columns: 1fr;

      .more-list {
        grid-template-columns: 1fr;
      }
    }
  }
}
