@default-border-color: #edeeef;
@red-color: #FF290B;
@secondary-color: #ff00ae;
@screen-md: 768px;

#cart-done-step{
  width: 100%;
  .ant-typography{
    display: block;
  }

  .cart-done-wrapper{
    display: flex;
    width: 100%;
    .cart-done-left{
      width: 50%;
      border-right: 2px solid @default-border-color;
      padding-right: 100px;
      .cart-done-left-title{
        font-weight: bold;
        margin-bottom: 16px;
      }
      .cart-done-left-subtitle{
        font-weight: bold;
        margin-bottom: 14px;
      }
      .cart-done-step-wrapper{
        margin-top: 50px;
        .cart-done-step-one{
          margin-bottom: 30px;
        }
        .cart-done-step-two{
          margin-bottom: 30px;
        }
        .cart-done-step-three{
          .card-done-total-amount{
            color: @red-color;
            font-size: 28px;
            margin: 6px 0 10px;
            display: block;
            font-weight: bold;
            letter-spacing: 1px;
          }
        }
      }
    }

    .cart-done-right{
      width: 50%;
      padding: 60px;
      .cart-how-to-pay{
        color: @secondary-color;
        font-weight: bold;
        display: block;
        cursor: pointer;
      }
    }
  }

  .card-done-button-wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    margin-top: 60px;
    padding: 0 15px;
    .vui-button{
      min-width: 380px;
    }
  }

  @media only screen and (max-width: @screen-md){
    .cart-done-wrapper{
      flex-direction: column;
      padding: 0 15px;
      .cart-done-left{
        border-right: 0;
        width: 100%;
        border-bottom: 2px solid @default-border-color;
        padding-bottom: 30px;
      }
      .cart-done-right{
        width: 100%;
        padding: 30px 0 0;
      }
    }
    .card-done-button-wrapper{
      flex-direction: column;
      margin-top: 40px;
      .ant-spin-nested-loading{
        width: 100%;
        .vui-button{
          min-width: 100%;
        }
      }
    }
  }
}
